export function authHeader(url) {
  let access_token = localStorage.getItem("accessToken");
  
  if (access_token) {
    return {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      "x-tenant-origin":
        window.location.host === "localhost:3000"
          ? "bigappcompany.deep-travel.in"
          : window.location.host,
    };
  } else {
    return {
      "Content-Type": "application/json",
      "x-tenant-origin":
        window.location.host === "localhost:3000"
          ? "bigappcompany.deep-travel.in"
          : window.location.host,
    };
  }
}

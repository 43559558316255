import { getValue } from "@utils/lodash";
import Input from "../Forms/Input";
import {
  formHandleBooleanArrayChange,
  formHandleDateArrayChange,
  formHandleLookeupArrayChange,
  formHandleNumericArrayChange,
  formHandleSelectArrayChange,
  formHandleTextArrayChange,
  formHandleTimeArrayChange,
} from "@common/handleChange";
import { capitalizeFirstLetter } from "@common/text-helpers";
import Radio from "../Forms/Radio";
import Checkbox from "../Forms/Checkbox";
import TextArea from "../Forms/textarea";
import DateMonthYearPicker from "../Forms/Date";
import TimePicker from "../Forms/Time";
import SingleSelectSearchDropdown from "../CustomSearchableDropdown/SingleSelectSearchDropdown";
import { generateFieldOptions } from "./generate-options";
import MultiSelectDropdown from "../CustomSearchableDropdown/multi";
import { attachRolesInUser, dettachRolesInUser } from "@services/roles.service";
import { toast } from "react-toastify";

export const RenderForm = (props: any) => {
  const { setFields, item, index, formOptions, simpleValidator } = props;

  const pushIntoArray = (id: string) => {
    let allIDs: any = [...getValue(item, `field_value`, "")];
    if (!allIDs.includes(id)) {
      if (getValue(item, `field_value`, "") === "") {
        allIDs = [id];
      } else {
        allIDs.push(id);
      }
      return allIDs;
    } else {
      let filtered = allIDs.filter((item: string) => item !== id);
      return filtered;
    }
  };

  const handleId = async (id: string, field_name: string, field_value: any) => {
    if (field_name === "roles" && id) {
      let filtered =
        getValue(field_value, `length`, 0) > 0 &&
        field_value.filter((item: string) => item === id);
      if (getValue(filtered, `length`, 0) === 0) {
        try {
          let resp = await attachRolesInUser(getValue(props, `id`, ""), {
            role_ids: [id],
          });
          if (resp) {
            toast.success("Attached successfully");
            props.getUserForms();
          }
        } catch (error) {}
      } else {
        try {
          let resp = await dettachRolesInUser(getValue(props, `id`, ""), id);
          if (resp) {
            toast.success("Removed successfully");
            props.getUserForms();
          }
        } catch (error) {}
      }
    }
  };
  const confirmSelectKey = (name: string) => {
    switch (name) {
      case "travel_type":
        return "value";
      case "gender":
        return "value";
      default:
        return "id";
    }
  };
  switch (
    getValue(item, `data_type`, "") +
    "-" +
    getValue(item, `sub_data_type`, "")
  ) {
    case "text-single_line_text":
      return (
        <div className="form_builder_field">
          <Input
            {...item}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              formHandleTextArrayChange(index, e, setFields)
            }
            value={getValue(item, `field_value`, "")}
            type={"text"}
            placeholder={`Enter ${getValue(item, `display_name`, "")}`}
            label={`${capitalizeFirstLetter(
              getValue(item, `display_name`, "")
            )}`}
            validator={getValue(item, `is_mandatory`, false) && simpleValidator}
            mandatory={getValue(item, `is_mandatory`, false)}
            style={{ height: "38px" }}
          />
        </div>
      );
    case "radio-boolean":
      return (
        <div className="form_builder_field">
          <Radio
            {...item}
            onChange={(e: any) =>
              formHandleBooleanArrayChange(
                index,
                e,
                setFields,
                getValue(item, `field_value`, "") === true ? false : true
              )
            }
            value={getValue(item, `field_value`, "")}
            checked={getValue(item, `field_value`, "") ? true : false}
            type={getValue(item, `data_type`, "")}
            placeholder={`Enter ${getValue(item, `display_name`, "")}`}
            label={`${capitalizeFirstLetter(
              getValue(item, `display_name`, "")
            )}`}
            validator={getValue(item, `is_mandatory`, false) && simpleValidator}
            mandatory={getValue(item, `is_mandatory`, false)}
          />
        </div>
      );
    case "checkbox-boolean":
      return (
        <div className="form_builder_field">
          <Checkbox
            {...item}
            onChange={(e: any) =>
              formHandleBooleanArrayChange(
                index,
                e,
                setFields,
                getValue(item, `field_value`, "") === true ? false : true
              )
            }
            value={getValue(item, `field_value`, "")}
            checked={getValue(item, `field_value`, "") ? true : false}
            type={getValue(item, `data_type`, "")}
            placeholder={`Enter ${getValue(item, `display_name`, "")}`}
            label={`${capitalizeFirstLetter(
              getValue(item, `display_name`, "")
            )}`}
            validator={getValue(item, `is_mandatory`, false) && simpleValidator}
            mandatory={getValue(item, `is_mandatory`, false)}
          />
        </div>
      );
    case "text-multi_line_text":
      return (
        <div className="form_builder_field">
          <TextArea
            {...item}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              formHandleTextArrayChange(index, e, setFields)
            }
            value={getValue(item, `field_value`, "")}
            type={"textarea"}
            label={`${getValue(item, `display_name`, "")}`}
            placeholder={`Enter ${getValue(item, `display_name`, "")}`}
            rows={5}
            validator={getValue(item, `is_mandatory`, false) && simpleValidator}
            mandatory={getValue(item, `is_mandatory`, false)}
          />
        </div>
      );
    case "numerical-single_line_text":
      return (
        <div className="form_builder_field">
          <Input
            {...item}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              formHandleNumericArrayChange(index, e, setFields)
            }
            value={getValue(item, `field_value`, "")}
            type={"number"}
            placeholder={`Enter ${getValue(item, `display_name`, "")}`}
            label={`${getValue(item, `display_name`, "")}`}
            validator={getValue(item, `is_mandatory`, false) && simpleValidator}
            mandatory={getValue(item, `is_mandatory`, false)}
          />
        </div>
      );
    case "numerical-percentage":
      return (
        <div className="form_builder_field">
          <Input
            {...item}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              formHandleNumericArrayChange(index, e, setFields)
            }
            value={getValue(item, `field_value`, "")}
            type={"number"}
            placeholder={`Enter ${getValue(item, `display_name`, "")}`}
            label={`${getValue(item, `display_name`, "")}`}
            validator={getValue(item, `is_mandatory`, false) && simpleValidator}
            mandatory={getValue(item, `is_mandatory`, false)}
          />
        </div>
      );
    case "numerical-decimal":
      return (
        <div className="form_builder_field">
          <Input
            {...item}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              formHandleNumericArrayChange(index, e, setFields)
            }
            value={getValue(item, `field_value`, "")}
            type={"number"}
            placeholder={`Enter ${getValue(item, `display_name`, "")}`}
            label={`${getValue(item, `display_name`, "")}`}
            validator={getValue(item, `is_mandatory`, false) && simpleValidator}
            mandatory={getValue(item, `is_mandatory`, false)}
          />
        </div>
      );
    case "numerical-number":
      return (
        <div className="form_builder_field">
          <Input
            {...item}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              formHandleNumericArrayChange(index, e, setFields)
            }
            value={getValue(item, `field_value`, "")}
            type={"number"}
            placeholder={`Enter ${getValue(item, `display_name`, "")}`}
            label={`${getValue(item, `display_name`, "")}`}
            validator={getValue(item, `is_mandatory`, false) && simpleValidator}
            mandatory={getValue(item, `is_mandatory`, false)}
          />
        </div>
      );
    case "email-email":
      return (
        <div className="form_builder_field">
          <Input
            {...item}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              formHandleTextArrayChange(index, e, setFields)
            }
            value={getValue(item, `field_value`, "")}
            type={"email"}
            placeholder={`Enter ${getValue(item, `display_name`, "")}`}
            label={`${getValue(item, `display_name`, "")}`}
            validator={getValue(item, `is_mandatory`, false) && simpleValidator}
            validatoryType={
              getValue(item, `required`, false) && "required|email"
            }
            mandatory={getValue(item, `is_mandatory`, false)}
          />
        </div>
      );
    case "phone-phone":
      return (
        <div className="form_builder_field">
          <Input
            {...item}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              formHandleTextArrayChange(index, e, setFields)
            }
            type={"text"}
            value={getValue(item, `field_value`, "")}
            placeholder={`Enter ${getValue(item, `display_name`, "")}`}
            label={`${getValue(item, `display_name`, "")}`}
            validator={getValue(item, `is_mandatory`, false) && simpleValidator}
            validatoryType={
              getValue(item, `required`, false) && "required|min:10|max:10"
            }
            mandatory={getValue(item, `is_mandatory`, false)}
          />
        </div>
      );
    case "url-url":
      return (
        <div className="form_builder_field">
          <Input
            {...item}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              formHandleTextArrayChange(index, e, setFields)
            }
            value={getValue(item, `field_value`, "")}
            type={"url"}
            placeholder={`Enter ${getValue(item, `display_name`, "")}`}
            label={`${getValue(item, `display_name`, "")}`}
            validator={getValue(item, `is_mandatory`, false) && simpleValidator}
            mandatory={getValue(item, `is_mandatory`, false)}
          />
        </div>
      );
    case "date-date":
      return (
        <div className="form_builder_field">
          <DateMonthYearPicker
            {...item}
            onChange={(e: any) =>
              formHandleDateArrayChange(index, e, setFields)
            }
            value={getValue(item, `field_value`, "")}
            type={"text"}
            label={`${getValue(item, `display_name`, "")}`}
            placeholder={`Enter ${getValue(item, `display_name`, "")}`}
            validator={getValue(item, `is_mandatory`, false) && simpleValidator}
            mandatory={getValue(item, `is_mandatory`, false)}
            maxDate={new Date()}
          />
        </div>
      );
    case "date-date_time":
      return (
        <div className="form_builder_field">
          <TimePicker
            {...item}
            onChange={(e: any) =>
              formHandleTimeArrayChange(index, e, setFields)
            }
            value={getValue(item, `field_value`, "")}
            type={"text"}
            label={`${getValue(item, `display_name`, "")}`}
            placeholder={`Enter ${getValue(item, `display_name`, "")}`}
            validator={getValue(item, `is_mandatory`, false) && simpleValidator}
            showTimeInput
            mandatory={getValue(item, `is_mandatory`, false)}
            maxDate={new Date()}
          />
        </div>
      );
    case "picklist-single_select":
      return (
        <div className="form_builder_field">
          <SingleSelectSearchDropdown
            {...item}
            placeholder={`Enter ${getValue(item, `display_name`, "")}`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              confirmSelectKey(getValue(item, `field_name`, "")) === "value"
                ? formHandleSelectArrayChange(index, e, setFields)
                : formHandleLookeupArrayChange(index, e, setFields)
            }
            label={`label`}
            validator={getValue(item, `is_mandatory`, false) && simpleValidator}
            width={"100%"}
            data={generateFieldOptions(
              getValue(item, `field_name`, ""),
              getValue(formOptions, `[${getValue(item, `field_name`, "")}]`, [])
            )}
            value={getValue(item, `field_value`, "")}
            selectKey={confirmSelectKey(getValue(item, `field_name`, ""))}
            name={getValue(item, `display_name`, "")}
            mandatory={getValue(item, `is_mandatory`, false)}
            showLabel
          />
        </div>
      );
    case "lookup-single_select":
      return (
        <div className="form_builder_field">
          <SingleSelectSearchDropdown
            {...item}
            placeholder={`Enter ${getValue(item, `display_name`, "")}`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              formHandleLookeupArrayChange(index, e, setFields)
            }
            label={`label`}
            validator={getValue(item, `is_mandatory`, false) && simpleValidator}
            width={"100%"}
            value={getValue(item, `field_value`, "")}
            name={getValue(item, `display_name`, "")}
            lookup_api={getValue(item, `field_name`, "")}
            lookup_id={getValue(item, `field_value`, "")}
            mandatory={getValue(item, `is_mandatory`, false)}
            showLabel
          />
        </div>
      );
    case "lookup-multi_select":
      return (
        <div className="form_builder_field">
          <MultiSelectDropdown
            {...item}
            placeholder={`Enter ${getValue(item, `display_name`, "")}`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              // formHandleLookeupArrayChange(index, e, setFields)
              getValue(props, `id`, "") &&
              getValue(item, `field_name`, "") === "roles"
                ? handleId(
                    getValue(e, `id`, ""),
                    getValue(item, `field_name`, ""),
                    getValue(item, `field_value`, [])
                  )
                : setFields((options: any) => {
                    return options.map((x: object, i: number) => {
                      if (i !== index) return x;
                      return {
                        ...x,
                        field_value: pushIntoArray(getValue(e, `id`, "")),
                      };
                    });
                  })
            }
            label={`label`}
            validator={getValue(item, `is_mandatory`, false) && simpleValidator}
            width={"100%"}
            value={getValue(item, `field_value`, "")}
            name={getValue(item, `display_name`, "")}
            lookup_api={getValue(item, `field_name`, "")}
            lookup_id={getValue(item, `field_value`, "")}
            mandatory={getValue(item, `is_mandatory`, false)}
            showLabel
          />
        </div>
      );
    case "lookup-currency":
      return (
        <div className="form_builder_field">
          <SingleSelectSearchDropdown
            {...item}
            placeholder={`Enter ${getValue(item, `display_name`, "")}`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              formHandleLookeupArrayChange(index, e, setFields)
            }
            label={`label`}
            validator={getValue(item, `is_mandatory`, false) && simpleValidator}
            width={"100%"}
            value={getValue(item, `field_value`, "")}
            name={getValue(item, `display_name`, "")}
            lookup_api={getValue(item, `field_name`, "")}
            mandatory={getValue(item, `is_mandatory`, false)}
            showLabel
          />
        </div>
      );
    default:
      return null;
  }
};

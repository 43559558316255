// https://stackoverflow.com/questions/32540667/moment-js-utc-to-local-time
import { getValue } from "@utils/lodash";
import moment from "moment-timezone";
import "moment-timezone";

let user = getValue(localStorage, `user`, "")
  ? JSON.parse(getValue(localStorage, `user`, {}))
  : {};
let timezone = getValue(user, `user_timezone`, "Asia/Kolkata");

export const todayDate = (date: any) => {
  if (date) {
    return new Date(moment(date).format("YYYY-MM-DD"));
  }
};

export const getDiffInDates = (date: any, days: any) => {
  if (date && days >= 0) {
    var d = date;
    d.setDate(d.getDate() - days);
    return d;
  }
};

export const getAdditionInDates = (date: any, days: any) => {
  if (date && days) {
    var d = date;
    d.setDate(d.getDate() + days);
    return d;
  }
};

export const convertDate = (date: any) => {
  if (date) {
    return moment(date).format("YYYY-MM-DD");
  } else {
    return null;
  }
};
export const convertDateAsString = (date: any) => {
  if (date) {
    return moment(date).format("DD MMM YYYY");
  } else {
    return null;
  }
};

export const convertCurrentDate = (date: any) => {
  if (date) {
    return date?moment(date).tz(timezone).format("DD MMM YYYY"):null;
    // return moment.utc(date).format("DD MMM YYYY");
  } else {
    return null;
  }
};

export const convertCurrentTime = (date: any) => {
  if (date) {
    return date?moment(date).tz(timezone).format("hh:mm a"):null;
    // return moment.utc(date).format("DD MMM YYYY");
  } else {
    return null;
  }
};

export const convertCurrentDateWithTime = (date: any) => {
  if (date) {
    // return moment.utc(date).format("DD MMM YYYY, hh:mm a");
    return moment(date).tz(timezone).format("DD MMM YYYY, hh:mm a");
    // return moment.utc(date).format("DD MMM YYYY, hh:mm a");
  } else {
    return null;
  }
};

export const currentDate = () => {
  // return moment(new Date()).tz(timezone).format("DD MMM YYYY");
  return Date.now();
};

export const NewDate = (date: any) => {
  if (date) {
    return new Date(date);
  } else {
    return null;
  }
};

export const convertDateTime = (time: any) => {
  const formattedTime = moment(time).utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
  return formattedTime;
};

const convertDate24Time = (time:string) => {
  if(time){
  const formattedTime = moment(time).format('hh:mm:ss');
  return formattedTime;
  }else{
    return null
  }
};

import DocumentSvgComponent from "@assets/svg-components/document";
import ReportsSvgComponent from "@assets/svg-components/sidebar/reports";
import ViolationSvgComponent from "@assets/svg-components/violation";
import { getColorStyle, getSingleColor } from "@common/color";
import { convertCurrentDate } from "@common/date-helpers";
import {
  concatString,
  formatAmount,
  formatText,
  getFirstLetterOfEachString,
} from "@common/text-helpers";
import ProfileCircle from "@components/ProfileCircle";
import { getValue } from "@utils/lodash";
import { Tooltip } from "react-tooltip";

function ReportsTable(props: any) {
  return (
    <div>
      <div>
        <table className="datatable_table">
          <thead className="datatable_table_thead">
            <th className="datatable_table_th" style={{ width: "70px" }}></th>
            <th className="datatable_table_th">
              <h6 className="">Report ID</h6>
              <p className="">Submitted On</p>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Report Name</h6>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Total</h6>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Submitted by</h6>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Approval Status</h6>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Action</h6>
            </th>
          </thead>
          <tbody className="datatable_table_tbody">
            {getValue(props, `list`, []).map((item: any, index: number) => {
              return (
                <tr key={index} className="datatable_table_tr">
                  <td className="datatable_table_td" style={{ width: "70px" }}>
                    <ReportsSvgComponent
                      color={getSingleColor(
                        getValue(item, `approval_status`, "")
                      )}
                      size={24}
                    />
                  </td>
                  <td
                    className="datatable_table_td align-items-center"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <div className="d-flex">
                      <div>
                        <h6 className="header_text color_primary">
                          {getValue(item, `report_id`, "")}
                        </h6>
                        <p className="small_text">
                          {/* {convertDate(getValue(item, `submitted_at`, ""))} */}
                          {convertCurrentDate(
                            getValue(item, `submitted_at`, "")
                          )}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <h6 className="">
                      {concatString(getValue(item, `report_name`, ""), 30)}
                    </h6>
                  </td>
                  <td
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                    className={`datatable_table_td`}
                  >
                    <h6 className="">
                      {getValue(item, `currency_symbol`, "")}{" "}
                      {formatAmount(getValue(item, `total`, ""))}
                    </h6>
                  </td>
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    {getValue(item, `created_by.email`, "") ? (
                      <div className="d-flex align-items-center">
                        <ProfileCircle
                          name="created_by"
                          item={item}
                          value={getValue(item, `created_by.first_name`, "")}
                        />
                        <div>
                          <h6>
                            {" "}
                            {getValue(item, `created_by.first_name`, "")}{" "}
                            {getValue(item, `created_by.middle_name`, "")}{" "}
                            {getValue(item, `created_by.last_name`, "")}
                          </h6>
                          <p>{getValue(item, `created_by.email`, "")}</p>
                        </div>
                      </div>
                    ) : (
                      <p className="text-center ms-5">-</p>
                    )}
                  </td>
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <h6
                      className=""
                      style={getColorStyle(
                        getValue(item, `approval_status`, "")
                      )}
                    >
                      {formatText(getValue(item, `approval_status`, ""))}
                    </h6>
                  </td>
                  <td className="datatable_table_td">
                    <div className="d-flex align-items-center">
                      <div
                        data-tooltip-id={
                          getValue(item, `receipts.length`, 0) > 0
                            ? "my-tooltip-document"
                            : ""
                        }
                        data-tooltip-content="This expense contains receipts"
                      >
                        <DocumentSvgComponent
                          color={
                            getValue(item, `receipts.length`, 0) > 0
                              ? "#276EF1"
                              : "lightgray"
                          }
                        />
                      </div>
                      <div
                        data-tooltip-id={
                          !getValue(
                            item,
                            `expense_entries[${0}].is_policy_violated`,
                            false
                          )
                            ? ""
                            : "my-tooltip"
                        }
                        data-tooltip-content="This expense violated certain policies"
                        className={`ms-4`}
                      >
                        <ViolationSvgComponent
                          color={
                            getValue(
                              item,
                              `expense_entries[${0}].is_policy_violated`,
                              false
                            )
                              ? "#ff8a00"
                              : "lightgray"
                          }
                          size={20}
                        />
                      </div>
                    </div>
                  </td>
                  {/* <td className="datatable_table_td">
                  <div className="more-dropdown">
                    <UncontrolledDropdown>
                      <DropdownToggle>
                        <div className="switch_header__dropdown m-0">
                          <div className="dropdown-content d-flex cursor-pointer">
                            <img
                              src="/images/more.svg"
                              width="24px"
                              height="24px"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() =>
                            props.handleNavigate(getValue(item, `id`, ""))
                          }
                        >
                          <div className={`more-dropdown-item `}>
                            <p>Edit</p>
                          </div>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            props.sendInviteUser(getValue(item, `id`, ""))
                          }
                        >
                          <div className={`more-dropdown-item `}>
                            <p>View</p>
                          </div>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            props.handleDeleteFun(
                              getValue(item, `id`, ""),
                              getValue(item, `title`, "")
                            )
                          }
                        >
                          <div className={`more-dropdown-item `}>
                            <p>Delete</p>
                          </div>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Tooltip id="my-tooltip" place="bottom" />
      <Tooltip id="my-tooltip-document" place="bottom" />
    </div>
  );
}

export default ReportsTable;

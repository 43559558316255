import React, { useEffect, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import ModulesNavigation from "../../sub-header";
import ExpenseTypePopup from "./add-popup";
import DeleteModal from "@common/popup/DeleteModal";
import { getValue } from "@utils/lodash";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  createExpenseType,
  deleteExpenseType,
  getAllExpenseTypes,
  getSpecificExpenseType,
  updateExpenseType,
  updateExpenseTypeBulk,
} from "@services/expense-type.service";
import { toast } from "react-toastify";
import SearchHeader from "@components/common/SearchHeader";
import GearLoader from "@components/common/GearLoader";
import ExpenseTypesAccountTable from "./table";
import Notfound from "@components/Not-Found";
import PaginationPage from "@components/pagination/pagination";
import { getAllPolicies } from "@services/policy.service";
import { formatText } from "@common/text-helpers";
import NavigationBar from "@components/NotificationBar";
import NoAccessPage from "@components/common/NoAccess";
import SkeletonTable from "@components/common/Skeleton/skeleton-table";

function AdminExpenseTypes(props: any) {
  const navigate = useNavigate();
  const params = useParams();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                               UseState section                             */
  /* -------------------------------------------------------------------------- */

  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length === 0 && props.permissionAPITriggered) {
      getData();
    }
    getPoliciesList();
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
      getData();
      if (params.page_no) {
        setPage_no(parseInt(params.page_no));
      }
      if (params.search_text) {
        setSearchInput(params.search_text);
        setShowSearchTextbox(true);
      }
      if (params.limit) {
        setLimit(parseInt(params.limit));
      }
    }
  }, [window.location.href, props.permissionAPITriggered]);

  /* -------------------------------------------------------------------------- */
  /*                                 API section                                */
  /* -------------------------------------------------------------------------- */
  const getData = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    try {
      let payload = {
        page_no: params.page_no ? parseInt(params.page_no) : 1,
        page_size: params.limit ? parseInt(params.limit) : limit,
        search_text: params.search_text,
      };
      let queryRequest = QueryRequestHelper(payload);
      setIsLoading(true);
      let resp = await getAllExpenseTypes(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.data`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload = {
      ...params,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);

    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeLimit = (limit: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      ...params,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search_text, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.search_text) {
      delete params.search_text;
      let payload: any = { ...params };
      // removing null values
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };

  const handleChangeSearch = async (e: any) => {
    setSearchInput(e.target.value);
    let payload: any = {
      ...urlParams,
      page_no: 1,
      search_text: e.target.value,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    if (!e.target.value) {
      getData();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Delete section                             */
  /* -------------------------------------------------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteEntity = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };

  const handleDeleteFunction = async () => {
    try {
      let resp = await deleteExpenseType(deleteId);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getData();
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const [selectedIDs, setSelectedIDs] = useState<any>([]);

  const handleCheckUsers = (id: string) => {
    let userIDs =
      getValue(selectedIDs, `length`, 0) > 0
        ? selectedIDs.map((item: string) => item)
        : [];
    if (userIDs.includes(id)) {
      let filterIDs = selectedIDs.filter((item: string) => item !== id);
      setSelectedIDs(filterIDs);
    } else {
      selectedIDs.push(id);
      setSelectedIDs([...selectedIDs]);
    }
  };
  const handleCheckAll = (status: string) => {
    if (status === "All") {
      setSelectedIDs([]);
    } else {
      let userIDs =
        getValue(list, `length`, 0) > 0
          ? list.map((item: object) => getValue(item, `id`, ""))
          : [];
      setSelectedIDs(userIDs);
    }
  };
  const handleChangeActiveStatus = async (value: string) => {
    try {
      let resp = await updateExpenseTypeBulk({
        ids: selectedIDs,
        is_active: value === "active" ? true : false,
      });
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSelectedIDs([]);
        getData();
      } else {
        getData();
      }
    } catch (error) {}
  };
  const [check, setCheck] = useState(false);
  const handleRemoveNavItems = () => {
    setCheck(!check);
    setSelectedIDs([]);
  };
  const handleNavigateUserEntity = () => {
    if (getValue(selectedIDs, `[${0}]`, "")) {
      // navigate(
      //   `/admin/user/entity-mapping/${getValue(selectedIDs, `[${0}]`, "")}`
      // );
    } else {
      toast.error("Please select Email Template");
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Edit section                             */
  /* -------------------------------------------------------------------------- */

  const [editId, setEditId] = useState("");
  const handleEditEntity = async (id: string) => {
    setEditId(id);
    try {
      let resp = await getSpecificExpenseType(id);
      if (resp) {
        setRequest({
          ...request,
          expense_type_name: getValue(resp, `data.expense_type_name`, ""),
          expense_type_code: getValue(resp, `data.expense_type_code`, ""),
          expense_category: getValue(resp, `data.expense_category.id`, ""),
          description: getValue(resp, `data.description`, ""),
          is_mileage: getValue(resp, `data.is_mileage`, false),
          policy_ids:
            getValue(resp, `data.policies.length`, 0) > 0
              ? getValue(resp, `data.policies`, []).map((item: object) => ({
                  ...item,
                  value: formatText(getValue(item, `policy_name`, "")),
                  label: formatText(getValue(item, `policy_name`, "")),
                }))
              : [],
        });
        setShowUpdatePopup(true);
      }
    } catch (error) {}
  };

  /* ---------------------------  Insert Modal  ----------------------------- */

  const intialData = {
    expense_type_name: "",
    expense_type_code: "",
    description: "",
    expense_category: "",
    policy_ids: "",
    is_mileage: false,
  };

  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const handlePopup = () => {
    setShowUpdatePopup(!showUpdatePopup);
    setRequest({
      ...intialData,
    });
  };
  const [request, setRequest] = useState({
    ...intialData,
  });

  const handleReset = () => {
    setRequest({
      ...intialData,
    });
    setEditId("");
  };
  const handleChangeText = (e: any) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      let payload: any = request;
      payload.policy_ids =
        getValue(payload, `policy_ids.length`, 0) > 0
          ? getValue(payload, `policy_ids`, []).map((item: object) =>
              getValue(item, `id`, "")
            )
          : [];
      Object.keys(payload).forEach((key) => {
        if (!payload[key] || payload[key] === undefined) {
          delete payload[key];
        }
      });
      delete payload.policy_id;
      setSubmitLoading(true);
      if (editId) {
        let resp = await updateExpenseType(editId, payload);
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          setSubmitLoading(false);
          setShowUpdatePopup(false);
          getData();
          handleReset();
          setEditId("");
        } else {
          setSubmitLoading(false);
        }
      } else {
        let resp = await createExpenseType(payload);
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          setSubmitLoading(false);
          setShowUpdatePopup(false);
          getData();
          handleReset();
          setEditId("");
        } else {
          setSubmitLoading(false);
        }
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  /* ---------------------------  Policy List  ----------------------------- */

  const [policyList, setPolicyList] = useState([]);
  const getPoliciesList = async () => {
    let paylod = {
      search_text: search_text,
      page_no: 1,
      page_size: 100,
      // is_active:true
    };
    let queryRequest = QueryRequestHelper(paylod);
    try {
      let resp = await getAllPolicies(queryRequest);
      if (resp) {
        let data =
          getValue(resp, `data.data.length`, 0) > 0
            ? getValue(resp, `data.data`, []).map((item: object) => ({
                ...item,
                value: formatText(getValue(item, `policy_name`, "")),
                label: formatText(getValue(item, `policy_name`, "")),
                workflow_name: getValue(item, `policy_name`, ""),
              }))
            : [];
        setPolicyList(data);
      }
    } catch (error) {}
  };
  const dropdownOptions = [{ label: "Expense Type", value: "EXPENSE_TYPE" }];
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "admin_modules_expenses"
      ) ? (
        <NoAccessPage />
      ) : (
        <>
          <ModulesNavigation
            headerTitle={"Fields"}
            name={`expenses`}
            hideSubmit
            permissions={getValue(props, `permissions`, [])}
          />
          {getValue(selectedIDs, `length`, 0) > 0 && (
            <NavigationBar
              selectedIDs={selectedIDs}
              // isMarkInactiveSectionVisible={true}
              handleChangeActiveStatus={handleChangeActiveStatus}
              text={"MARK INACTIVE"}
              handleRemoveNavItems={handleRemoveNavItems}
              multi={
                getValue(props, `permissions`, []).includes("update")
                  ? true
                  : false
              }
              permissions={getValue(props, `permissions`, [])}
              entityMapping={
                getValue(props, `products`, []).includes("SELFCOMP")
                  ? true
                  : false
              }
              handleNavigateUserEntity={handleNavigateUserEntity}
            />
          )}
          <div className="admin-wrapper position-relative">
            <div className="admin-wrapper position-relative">
              <SearchHeader
                searchInput={search_text}
                showSearchTextbox={showSearchTextbox}
                toggleSearch={toggleSearch}
                toggleSearchClose={toggleSearchClose}
                handleChangeSearch={handleChangeSearch}
                isAdd={
                  getValue(props, `permissions`, []).includes("update")
                    ? true
                    : false
                }
                add={
                  getValue(props, `permissions`, []).includes("create")
                    ? "true"
                    : "false"
                }
                handleAddFun={() => handlePopup()}
                commonUpload={
                  getValue(props, `permissions`, []).includes("update")
                    ? true
                    : false
                }
                download={
                  getValue(props, `permissions`, []).includes("update")
                    ? true
                    : false
                }
                download_type={"EXPENSE_TYPE"}
                // log_name={"USER"}
                // logs
                dropdownOptions={dropdownOptions}
              />
            </div>
          </div>
          <div
            className={
              getValue(selectedIDs, `length`, 0) > 0
                ? "datatable_main_container_notifiction"
                : "datatable_main_container"
            }
            style={{ overflowY: isLoading ? "hidden" : "auto" }}
          >
            {isLoading || !props.permissionAPITriggered ? (
              // <GearLoader />
              <SkeletonTable />
            ) : getValue(list, `length`, 0) > 0 ? (
              <>
                <ExpenseTypesAccountTable
                  list={list}
                  selectedIDs={selectedIDs}
                  handleCheckUsers={handleCheckUsers}
                  handleCheckAll={handleCheckAll}
                  handleEditEntity={handleEditEntity}
                  permissions={getValue(props, `permissions`, [])}
                />
              </>
            ) : (
              <>
                {Object.keys(params).length !== 0 ? <Notfound /> : <Notfound />}
              </>
            )}
          </div>
          <div className="fixed_footer_wrapper d-flex justify-content-between">
            {list.length > 0 && (
              <img
                src="/images/building-illustration.svg"
                className="img-fluid"
              />
            )}
            {totalCount > 10 ? (
              <PaginationPage
                totalCount={totalCount}
                limit={limit}
                pageNumber={page_no}
                handleChangePagination={handleChangePagination}
                handleChangeLimit={handleChangeLimit}
              />
            ) : (
              <p className="show-rows-count mt-3 d-flex">
                Total Count:&nbsp;&nbsp;{" "}
                <p style={{ color: "rgb(13, 110, 253)" }}>{totalCount}</p>
              </p>
            )}
          </div>
          <ExpenseTypePopup
            showUpdatePopup={showUpdatePopup}
            setShowUpdatePopup={setShowUpdatePopup}
            request={request}
            handleChangeText={handleChangeText}
            handleSubmit={handleSubmit}
            submitLoading={submitLoading}
            editId={editId}
            setRequest={setRequest}
            handleReset={handleReset}
            policyList={policyList}
            setPolicyList={setPolicyList}
          />
          <DeleteModal
            isOpen={isDelete}
            handleModal={handleDelete}
            handleSubmit={handleDeleteFunction}
            deleteValue={deleteValue}
          />
        </>
      )}
    </DashboardLayout>
  );
}

export default AdminExpenseTypes;

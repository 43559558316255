import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import CreateTripsStickyHeader from "../components/Create/StickyHeader";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import {
  createTrip,
  getAllTripForm,
  getAllTripFormEdit,
  getTripValidateApproverFlowType,
  submitTrip,
  updateTrip,
} from "@services/trips.service";
import { RenderForm } from "@components/common/FormBuilder/render-form";
import TripsCreateHeader from "../components/Create/tripsHeader";
import TripsCreateFlightList from "../components/Create/Flight/flight-section";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import {
  removeEmptyProperties,
  removeNullOrUndefinedProperties,
  sortJSONObjectArray,
} from "@common/text-helpers";
import {
  FormAppendDataRequestHelper,
  FormRequestHelper,
} from "@components/common/FormBuilder/request-helper";
import {
  QueryRequestHelper,
  removeNullInObject,
} from "@common/query-request-helper";
import ReportSubmitModal from "@components/Reports/components/ReportSubmitPopup";
import TripCreateHotelSection from "../components/Create/hotel/hotel-section";
import TripCreateCarSection from "../components/Create/car/car-section";
import TripCreateTrainSection from "../components/Create/train/train-section";
import TripCreateBusSection from "../components/Create/bus/bus-section";
import { getAllCurrency } from "@services/currency.service";
import SubmitConfirmModal from "@common/popup/SubmitConfirmModal";
import TripsTabNavigation from "./tab-navigation";
import {
  generateBusPayload,
  generateCarPayload,
  generateFlightCreatePayload,
  generateHotelPayload,
  generatePrefillTripRequest,
  generateTrainPayload,
} from "./trip-request";
import { useLocation } from "react-router-dom";
import Loader from "@components/common/Loader";
import NoAccessPage from "@components/common/NoAccess";

function ClientTripsCreate(props: any) {
  let navigate = useNavigate();
  let params = useParams();
  let location = useLocation();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = React.useState(true);
  const [form, setForm] = useState([]);
  const [request, setRequest] = useState({
    air_travel_type: "one_way",
    class: "",
    time_preference: "",
    seat_type: "",
    meal_type: "",
  });
  const [itineraries, setItenaries] = useState([]);
  const [hotelItenaries, setHotelItenaries] = useState([]);
  const [carItenaries, setCarItenaries] = useState([]);
  const [trainItenaries, setTrainItenaries] = useState([]);
  const [busItenaries, setBusItenaries] = useState([]);

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (props.permissionAPITriggered) {
      getDefaultData();
      getData();
    }
  }, [props.permissionAPITriggered]);
  // useEffect(() => {
  //   if (getValue(params, `id`, "")) {
  //     getData();
  //   }
  // }, [getValue(params, `id`, ""),getValue(location, `key`, "")]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [travelModes, setTravelModes] = useState({});
  const getDefaultData = async () => {
    try {
      let resp = await getAllTripForm("");
      if (resp) {
        setTravelModes(getValue(resp, `data.travel_modes`, []));
        let arr = getValue(resp, `data.form_layout`, []).map((item: object) => {
          if (getValue(item, `field_name`, "") === "currency") {
            return {
              ...item,
              field_name: "currency_id",
            };
          } else {
            return {
              ...item,
            };
          }
        });
        setForm(arr);
      }
    } catch (error) {}
  };
  const getData = async () => {
    if (getValue(params, `id`, "")) {
      try {
        setIsLoading(true);
        let resp;
        if (getValue(params, `id`, "")) {
          resp = await getAllTripFormEdit(getValue(params, `id`, ""));
        } else {
          resp = await getAllTripForm("");
        }
        if (resp) {
          if (getValue(params, `id`, "")) {
            setForm(getValue(resp, `data.form_layout`, []));
            let length = getValue(resp, `data.form_layout.length`, 0);
            let array = getValue(
              resp,
              `data.form_layout`,
              []
            ).filter((item: object) => getValue(item, `is_visible`, false));
            let payload = {
              currency_code: getValue(resp, `data.trip.claimed_currency`, ""),
            };
            let queryRequest = QueryRequestHelper(payload);
            let trip = getValue(resp, `data.trip`, {});
            getInfo(trip);
            if (getValue(resp, `data.trip.claimed_currency`, "")) {
              let currency = await getAllCurrency(queryRequest);
              if (currency) {
                trip["currency"] = {
                  id: getValue(currency, `data.data[${0}].id`, ""),
                };
              }
            }
            for (let i = 0; i < length; i++) {
              FormAppendDataRequestHelper(array[i], trip);
            }
            setForm(array);
          } else {
            setForm(getValue(resp, `data.form_layout`, []));
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }else{
      setIsLoading(false)
    }
  };
  const getInfo = async (resp: any) => {
    const {
      flightTrips,
      flightItineraries,
      hotelItineraries,
      carItineraries,
      trainItineraries,
      busItineraries,
    } = generatePrefillTripRequest(resp);
    setRequest({
      ...request,
      air_travel_type: getValue(flightTrips, `[${0}].air_travel_type`, ""),
      class: getValue(flightTrips, `[${0}].flight.class`, ""),
      seat_type: getValue(flightTrips, `[${0}].flight.seat_type`, ""),
      meal_type: getValue(flightTrips, `[${0}].flight.meal_type`, ""),
    });
    setItenaries(flightItineraries);
    setHotelItenaries(hotelItineraries);
    setCarItenaries(carItineraries);
    setTrainItenaries(trainItineraries);
    setBusItenaries(busItineraries);
  };
  /* -------------------------------------------------------------------------- */
  /*                               Submit Section                               */
  /* -------------------------------------------------------------------------- */
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoading(true);
        let resp;
        let form_payload = FormRequestHelper(form);
        let flight = generateFlightCreatePayload(
          getValue(request, `air_travel_type`, ""),
          itineraries,
          request
        );
        let hotel = generateHotelPayload(hotelItenaries);
        let car = generateCarPayload(carItenaries, params);
        let train = generateTrainPayload(trainItenaries, params);
        let bus = generateBusPayload(busItenaries, params);
        let array = flight.concat(hotel, car, train, bus);
        let payload = {
          ...form_payload,
          itineraries: removeEmptyProperties(array),
        };
        if (getValue(params, `id`, "")) {
          resp = await updateTrip(
            getValue(params, `id`, ""),
            removeNullInObject(payload)
          );
        } else {
          resp = await createTrip(removeNullInObject(payload));
        }
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          navigate(
            getValue(params, `id`, "")
              ? `/client/trips/detail/${getValue(params, `id`, "")}`
              : `/client/trips/`
          );
          hideValidation();
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };
  const [tripLoading, setTripLoading] = useState(false);
  const handleCreateTrip = async () => {
    try {
      let form_payload = FormRequestHelper(form);
      let flight = generateFlightCreatePayload(
        getValue(request, `air_travel_type`, ""),
        itineraries,
        request
      );
      let hotel = generateHotelPayload(hotelItenaries);
      let car = generateCarPayload(carItenaries, params);
      let train = generateTrainPayload(trainItenaries, params);
      let bus = generateBusPayload(busItenaries, params);
      let array = flight.concat(hotel, car, train, bus);
      let payload = {
        ...form_payload,
        itineraries: removeEmptyProperties(array),
      };

      let resp;
      setTripLoading(true);

      if (getValue(params, `id`, "")) {
        resp = await updateTrip(
          getValue(params, `id`, ""),
          removeNullInObject(payload)
        );
      } else {
        resp = await createTrip(removeNullInObject(payload));
      }
      if (resp) {
        handleSubmitReport(getValue(resp, `data.id`, ""));
        hideValidation();
        setTripLoading(false);
      } else {
        setTripLoading(false);
        setIsOpen(false);
      }
    } catch (error) {
      setTripLoading(false);
    }
  };
  const hideValidation = () => {
    simpleValidator.current.fields = {};
    simpleValidator.current.hideMessages();
    forceUpdate(0);
  };

  /* --------------------------  Report Submission section  ----------------------- */

  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const [isWorkflowApprover, setIsWorkflowApprover] = useState(false);
  const [trip_id, setTrip_id] = useState("");
  const handleSubmitReport = async (id: string) => {
    try {
      setTrip_id(id);
      let resp = await getTripValidateApproverFlowType(id);
      if (resp) {
        setIsWorkflowApprover(getValue(resp, `data.isWorkflowApprover`, ""));
        if (getValue(resp, `data.isWorkflowApprover`, "")) {
          handleSubmitTrip(id);
        } else {
          handleModal();
        }
      }
    } catch (error) {}
  };
  const [submitTripLoading, setSubmitTripLoading] = useState(false);
  const [approverId, setApproverId] = useState("");
  const handleSubmitTrip = async (id: string) => {
    try {
      let requestPayload: any;
      if (isWorkflowApprover) {
        requestPayload = {
          id: id ? id : trip_id,
        };
      } else {
        requestPayload = {
          approver_id: approverId,
          id: id ? id : trip_id,
        };
      }
      setSubmitTripLoading(true);
      let resp = await submitTrip(
        removeNullOrUndefinedProperties(requestPayload)
      );
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        // if (!isWorkflowApprover) {
        //   handleModal();
        // }
        setIsOpen(false);
        setSubmitTripLoading(false);
        navigate(
          getValue(params, `id`, "")
            ? `/client/trips/detail/${getValue(params, `id`, "")}`
            : `/client/trips/`
        );
      } else {
        setSubmitTripLoading(false);
      }
    } catch (error) {
      setSubmitTripLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                           Confirm Submit section                           */
  /* -------------------------------------------------------------------------- */
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const handleConfirmModal = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setIsConfirmOpen(!isConfirmOpen);
    }
  };
  const handleConfirmSubmit = () => {
    handleCreateTrip();
    handleConfirmModal();
  };

  const [activeTab, setActiveTab] = useState("info");
  console.log(props)
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes("trips") ? (
        <NoAccessPage />
      )  : (
        <>
          <CreateTripsStickyHeader
            route={
              getValue(params, `id`, "")
                ? `/client/trips/detail/${getValue(params, `id`, "")}`
                : `/client/trips/`
            }
            submitLoading={submitLoading}
            handleSubmit={handleSubmit}
            handleCreateTrip={handleConfirmModal}
            tripLoading={tripLoading}
            id={getValue(params, `id`, "")}
            permissions={getValue(props, `permissions`, [])}
          />
          <TripsTabNavigation
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            flightList={itineraries}
            hotelList={hotelItenaries}
            carList={carItenaries}
            trainList={trainItenaries}
            busList={busItenaries}
            request={request}
            travelModes={travelModes}
          />
          <div className="trips_main_section">
            <div className="trips_flights_cotnainer">
              {activeTab === "info" && (
                <div className="form_flex_container">
                  <div className="form_trips_wrapper">
                    {getValue(form, `length`, 0) > 0
                      ? form.map((item: any, index: number) => {
                          let travel_type = form.filter(
                            (item: object) =>
                              getValue(item, `field_name`, "") === "travel_type"
                          );
                          if (
                            getValue(travel_type, `length`, 0) > 0 &&
                            (getValue(travel_type, `[${0}].field_value`, "") ===
                            "domestic"
                              ? getValue(item, `field_name`, false) !==
                                  "destination_country" &&
                                getValue(item, `field_name`, false) !==
                                  "is_visa_required"
                              : getValue(item, `field_name`, false))
                          )
                            if (getValue(item, `is_visible`, false))
                              return (
                                <div className="form_trips_wrapper_container">
                                  <RenderForm
                                    item={item}
                                    setFields={setForm}
                                    index={index}
                                    simpleValidator={simpleValidator}
                                  />
                                </div>
                              );
                        })
                      : null}
                  </div>
                </div>
              )}

              {activeTab === "flight" &&
                getValue(travelModes, `flight_reservation`, false) && (
                  <>
                    <TripsCreateFlightList
                      request={request}
                      itineraries={itineraries}
                      setItenaries={setItenaries}
                      validator={simpleValidator}
                      setRequest={setRequest}
                      hideValidation={hideValidation}
                      travelModes={travelModes}
                    />
                  </>
                )}
              {activeTab === "hotel" &&
                getValue(travelModes, `hotel_reservation`, false) && (
                  <>
                    <TripCreateHotelSection
                      hotelItenaries={hotelItenaries}
                      setHotelItenaries={setHotelItenaries}
                      validator={simpleValidator}
                      hideValidation={hideValidation}
                    />
                  </>
                )}

              {activeTab === "car" &&
                getValue(travelModes, `car_rental`, false) && (
                  <>
                    <TripCreateCarSection
                      itenaries={carItenaries}
                      setItenaries={setCarItenaries}
                      validator={simpleValidator}
                      hideValidation={hideValidation}
                    />
                  </>
                )}
              {activeTab === "train" && getValue(travelModes, `train`, false) && (
                <>
                  <TripCreateTrainSection
                    itenaries={trainItenaries}
                    setItenaries={setTrainItenaries}
                    validator={simpleValidator}
                    hideValidation={hideValidation}
                  />
                </>
              )}
              {activeTab === "bus" && getValue(travelModes, `bus`, false) && (
                <>
                  <TripCreateBusSection
                    itenaries={busItenaries}
                    setItenaries={setBusItenaries}
                    validator={simpleValidator}
                    hideValidation={hideValidation}
                  />
                </>
              )}
            </div>
          </div>
          <ReportSubmitModal
            isOpen={isOpen}
            handleModal={handleModal}
            handleSubmit={handleSubmitTrip}
            approverId={approverId}
            setApproverId={setApproverId}
            submitLoading={submitTripLoading}
          />
          <SubmitConfirmModal
            isOpen={isConfirmOpen}
            handleModal={handleConfirmModal}
            handleSubmit={handleConfirmSubmit}
          />
        </>
      )}
    </DashboardLayout>
  );
}

export default ClientTripsCreate;

import { getValue } from "@utils/lodash";
import moment from "moment";
import { convertDateTime } from "./date-helpers";

export const handleTextChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  object: object,
  setObject: any
) => {
  const { name, value } = e.target;
  setObject({
    ...object,
    [name]: value,
  });
};

export const handleNumberChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  object: object,
  setObject: any
) => {
  const { name, value } = e.target;
  setObject({
    ...object,
    [name]: value ? parseFloat(value) : 0,
  });
};

export const handleDateChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  name: string,
  object: object,
  setObject: any
) => {
  setObject({
    ...object,
    [name]: e,
  });
};

export const handleTextBoolean = (
  object: object,
  setObject: any,
  name: string,
  value: boolean
) => {
  setObject({
    ...object,
    [name]: value,
  });
};

export const handleSelectChange = (
  e: object,
  name: string,
  object: object,
  setObject: any
) => {
  setObject({
    ...object,
    [name]: getValue(e, `value`, ""),
  });
};

export const handleTextArrayChange = (
  i: number,
  e: any,
  key: string,
  setObject: any
) => {
  const { name, value } = e.target;
  setObject((object: any) => {
    return {
      ...object,
      [key]: object[key].map((x: any, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          [name]: value,
        };
      }),
    };
  });
};

export const handleTextArraySubmit = (
  i: number,
  name: any,
  value: any,
  key: string,
  setObject: any
) => {
  setObject((object: any) => {
    return {
      ...object,
      [key]: object[key].map((x: any, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          [name]: value,
        };
      }),
    };
  });
};

export const handleSelectArrayChange = (
  i: number,
  e: any,
  name: string,
  key: string,
  setObject: any
) => {
  setObject((object: any) => {
    return {
      ...object,
      [key]: object[key].map((x: any, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          [name]: e ? getValue(e, `value`, "") : null,
        };
      }),
    };
  });
};
export const handleSelectArrayChangeByKeyValue = (
  i: number,
  e: any,
  name: string,
  key: string,
  kevValue: string,
  setObject: any
) => {
  setObject((object: any) => {
    return {
      ...object,
      [key]: object[key].map((x: any, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          [name]: e ? getValue(e, `[${kevValue}]`, "") : null,
        };
      }),
    };
  });
};

export const handleSelectArrayChangeStaticValue = (
  i: number,
  key: string,
  name: string,
  value: string,
  setObject: any
) => {
  setObject((object: any) => {
    return {
      ...object,
      [key]: object[key].map((x: any, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          [name]: value,
        };
      }),
    };
  });
};
/* -------------------------------------------------------------------------- */
/*                     Form Builder Helper Section                            */
/* -------------------------------------------------------------------------- */
/**
 *
 * Array Text onchange
 * @requires
 *
 */
export const formHandleTextArrayChange = (
  i: number,
  e: any,
  setObject: any
) => {
  const { name, value } = e.target;
  //added for report name requirement
  if (value.length < 40)
    setObject((options: any) => {
      return options.map((x: object, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          field_value: value,
        };
      });
    });
};
export const formHandleTextArrayChangeByKey = (
  i: number,
  e: any,
  setObject: any
) => {
  const { name, value } = e.target;
  setObject((options: any) => {
    return options.map((x: object, index: number) => {
      if (index !== i) return x;
      return {
        ...x,
        [name]: value,
      };
    });
  });
};

/**
 *
 * Array Numeric onchange
 * @requires
 *
 */
const handleChange = (e: any) => {
  const userInput = e.target.value;
  const regex = /^[0-9.]*$/; // Regular expression to allow only digits and a single decimal point
  let input = userInput.slice(0, 6);

  if (regex.test(input)) {
    return input;
  } else {
    return input ? input.replace(/[^0-9.]/g, "") : "";
  }
};
export const formHandleNumericArrayChange = (
  i: number,
  e: any,
  setObject: any
) => {
  const { name, value } = e.target;
  if (value)
    setObject((options: any) => {
      return options.map((x: object, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          field_value: handleChange(e),
        };
      });
    });
  else
    setObject((options: any) => {
      return options.map((x: object, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          field_value: null,
        };
      });
    });
};

/**
 *
 * Array Boolean onchange
 * @requires
 *
 */
export const formHandleBooleanArrayChange = (
  i: number,
  e: any,
  setObject: any,
  field_value: any
) => {
  const { name, value } = e.target;
  setObject((options: any) => {
    return options.map((x: object, index: number) => {
      if (index !== i) return x;
      return {
        ...x,
        field_value: field_value,
      };
    });
  });
};

/**
 *
 * Array Select onchange
 * @requires
 *
 */

export const formHandleSelectArrayChange = (
  i: number,
  e: any,
  setObject: any
) => {
  if (getValue(e, `value`, ""))
    setObject((options: any) => {
      return options.map((x: object, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          field_value: getValue(e, `value`, ""),
        };
      });
    });
  else
    setObject((options: any) => {
      return options.map((x: object, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          field_value: null,
        };
      });
    });
};

export const formHandleSelectArrayChangeByKey = (
  i: number,
  e: any,
  key: string,
  setObject: any
) => {
  if (getValue(e, `value`, ""))
    setObject((options: any) => {
      return options.map((x: object, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          [key]: getValue(e, `value`, ""),
        };
      });
    });
  else
    setObject((options: any) => {
      return options.map((x: object, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          [key]: null,
        };
      });
    });
};

export const formHandleSelectArrayChangeByKeyValue = (
  i: number,
  e: any,
  key: string,
  type: string,
  setObject: any
) => {
  if (getValue(e, `value`, ""))
    setObject((options: any) => {
      return options.map((x: object, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          [key]: getValue(e, `[${type}]`, ""),
        };
      });
    });
  else
    setObject((options: any) => {
      return options.map((x: object, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          [key]: null,
        };
      });
    });
};

export const formHandleBooleanArrayChangeByKey = (
  i: number,
  key: string,
  value: boolean,
  setObject: any
) => {
  setObject((options: any) => {
    return options.map((x: object, index: number) => {
      if (index !== i) return x;
      return {
        ...x,
        [key]: value,
      };
    });
  });
};

/**
 *
 * Array Lookup onchange
 * @requires
 *
 */

export const formHandleLookeupArrayChange = (
  i: number,
  e: any,
  setObject: any
) => {
  if (getValue(e, `id`, ""))
    setObject((options: any) => {
      return options.map((x: object, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          field_value: getValue(e, `id`, ""),
        };
      });
    });
  else if (getValue(e, `name`, ""))
    setObject((options: any) => {
      return options.map((x: object, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          field_value: getValue(e, `name`, ""),
        };
      });
    });
  else
    setObject((options: any) => {
      return options.map((x: object, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          field_value: null,
        };
      });
    });
};

/**
 *
 * Array Date onchange
 * @requires
 *
 */

export const formHandleDateArrayChange = (
  i: number,
  e: any,
  setObject: any
) => {
  if (e)
    setObject((options: any) => {
      return options.map((x: object, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          field_value: moment(e).format("YYYY-MM-DD"),
        };
      });
    });
  else
    setObject((options: any) => {
      return options.map((x: object, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          field_value: null,
        };
      });
    });
};

export const formHandleDateArrayChangeByKey = (
  i: number,
  e: any,
  key: string,
  setObject: any
) => {
  if (e)
    setObject((options: any) => {
      return options.map((x: object, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          [key]: moment(e).format("YYYY-MM-DD"),
        };
      });
    });
  else
    setObject((options: any) => {
      return options.map((x: object, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          [key]: null,
        };
      });
    });
};

/**
 *
 * Array Time onchange
 * @requires
 *
 */

export const formHandleTimeArrayChange = (
  i: number,
  e: any,
  setObject: any
) => {
  if (e)
    setObject((options: any) => {
      return options.map((x: object, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          field_value: convertDateTime(e),
        };
      });
    });
  else
    setObject((options: any) => {
      return options.map((x: object, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          field_value: null,
        };
      });
    });
};

export const formHandleTimeArrayChangeByKey = (
  i: number,
  e: any,
  key: string,
  setObject: any
) => {
  if (e)
    setObject((options: any) => {
      return options.map((x: object, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          [key]: convertDateTime(e),
        };
      });
    });
  else
    setObject((options: any) => {
      return options.map((x: object, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          [key]: null,
        };
      });
    });
};

import { convertCurrentDate } from "@common/date-helpers";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  concatDotToString,
  concatString,
  formatText,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import Loader from "@components/common/Loader";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import {
  createExpenseEntryComment,
  deleteExpenseEntryComment,
  deleteExpenseEntryReceipts,
  deleteSpecificExpenseEntry,
  getAllExpenseEntries,
  getAllExpenseEntryComments,
  getSpecificExpenseEntry,
} from "@services/expense-entry.service";
import { getValue } from "@utils/lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ExpenseTabNavigation from "./DetailContainer/TabHeader/tabheader-navigation";
import DetailContainer from "./DetailContainer";
import { mediaUpload } from "@services/upload.service";
import DeleteModal from "@common/popup/DeleteModal";
import StickyHeader from "./StickyHeader";
import { uploadExpenseEntryReceipts } from "@services/expense-entry.service";
import ExpenseHeaderRightSection from "./components/ExpenseHeaderRightSection";
import ExpenseDocumentSection from "./components/ExpenseDocumentSection";
import ExpenseDetailSection from "./components/ExpenseDetailSection";
import ExpenseViolationSection from "./components/ExpenseViolationSection";
import ExpenseAmountSection from "./components/ExpenseAmountSection";
import LightGalleryPreview from "@common/lightgallery-preview";
import NoAccessPage from "@components/common/NoAccess";

function ClientExpenseDetail(props: any) {
  let navigate = useNavigate();
  let params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = React.useState(true);
  const [formInfo, setFormInfo] = useState({});
  const [listLoading, setListLoading] = useState(false);
  const [list, setList] = useState([]);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(urlParams).length === 0 && props.permissionAPITriggered) {
      getList();
      // getComment();
    }
    getComment();
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    if (Object.keys(urlParams).length > 0 && props.permissionAPITriggered) {
      if (urlParams.page_no) {
        setPage_no(parseInt(urlParams.page_no));
      }
      if (urlParams.search_text) {
        setSearchInput(urlParams.search_text);
        setShowSearchTextbox(true);
      }
      if (urlParams.limit) {
        setLimit(parseInt(urlParams.limit));
      }
      getList();
    }
  }, [window.location.href, props.permissionAPITriggered]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getList = async () => {
    try {
      setListLoading(true);
      let payload: any = {
        page_no: params.page_no ? parseInt(params.page_no) : 1,
        page_size: params.limit ? parseInt(params.limit) : limit,
        search_text: params.search_text,
        // status: params.status,
        sort_by: params.sort_by,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllExpenseEntries(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.expense_entries`, []));

        getData(getValue(params, `id`, ""));
        setListLoading(false);
      } else {
        setListLoading(false);
      }
    } catch (error) {
      setListLoading(false);
    }
  };
  const getData = async (id: string) => {
    try {
      setIsLoading(true);
      let resp = await getSpecificExpenseEntry(id);
      if (resp) {
        setFormInfo(getValue(resp, `data`, {}));
        setDocuments(getValue(resp, `data.receipts`, []));
        handleClickGallery(getValue(resp, `data`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search_text, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.search_text) {
      delete params.search_text;
      let payload: any = { ...params };
      let queryRequest = QueryRequestHelper(payload);

      navigate(`${window.location.pathname}?${queryRequest}`);
      // getData();
    }
  };
  const handleChangeSearch = async (e: any) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setSearchInput(e.target.value);

    let payload: any = {
      ...params,
      page_no: 1,
      search_text: e.target.value,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    if (!e.target.value) {
      // getData();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const [selectedIDs, setSelectedIDs] = useState<any>([]);
  const handleCheckUsers = (id: string) => {
    let userIDs =
      getValue(selectedIDs, `length`, 0) > 0
        ? selectedIDs.map((item: string) => item)
        : [];
    if (userIDs.includes(id)) {
      let filterIDs = selectedIDs.filter((item: string) => item !== id);
      setSelectedIDs(filterIDs);
    } else {
      selectedIDs.push(id);
      setSelectedIDs([...selectedIDs]);
    }
  };
  const handleCheckAll = (status: string) => {
    if (status === "All") {
      setSelectedIDs([]);
    } else {
      let userIDs =
        getValue(list, `length`, 0) > 0
          ? list.map((item: object) => getValue(item, `id`, ""))
          : [];
      setSelectedIDs(userIDs);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload = {
      ...params,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleChangeLimit = (limit: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      ...params,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  /* -------------------------------------------------------------------------- */
  /*                                 Onchange section                           */
  /* -------------------------------------------------------------------------- */

  const handleNavigate = (id: string) => {
    navigate(`/client/expenses/detail/${id}`);
  };

  const [activeTab, setActiveTab] = useState(1);
  const handleActiveTab = (activeTab: any) => {
    setActiveTab(activeTab);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Upload Section                                */
  /* -------------------------------------------------------------------------- */
  const fileTypes = ["PDF", "JPEG", "XLSX", "CSV", "XLSM", "XLX", "JPG", "PNG"];
  const [documents, setDocuments] = useState<any>([]);
  const [documentList, setDocumentList] = useState<any>([]);
  const [length, setLength] = useState(0);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadStaticLoading, setUploadStaticLoading] = useState(false);
  const handleUploadDocuments = async (e: any) => {
    if (getValue(params, `id`, "")) {
      let lengthOfUploadedFiles = Array.from(e).length;
      setLength(lengthOfUploadedFiles);
    }
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      try {
        setUploadStaticLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          setDocuments((prevDocuments: any) => [
            ...prevDocuments,
            getValue(resp, `data`, {}),
          ]);
          setDocumentList((prevDocuments: any) => [
            ...prevDocuments,
            getValue(resp, `data`, {}),
          ]);
          if (getValue(params, `id`, "")) {
            setUploadLoading(true);
          }
          e = null;
          setUploadStaticLoading(false);
        } else {
          e = null;
          setUploadStaticLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadStaticLoading(false);
      }
    });
  };
  useEffect(() => {
    if (
      length > 0 &&
      getValue(documentList, `length`, 0) > 0 &&
      getValue(documentList, `length`, 0) === length
    ) {
      uploadFiles();
    } else {
      setLength(0);
    }
  }, [length > 0 && getValue(documentList, `length`, 0) === length]);
  const uploadFiles = async () => {
    try {
      let payload = {};
      let requestPayload: any = payload;
      requestPayload["receipt_ids"] =
        getValue(documents, `length`, 0) > 0
          ? documents.map((item: object) => getValue(item, `id`, ""))
          : [];
      let resp = await uploadExpenseEntryReceipts(
        getValue(params, `id`, ""),
        removeNullOrUndefinedProperties(requestPayload)
      );
      if (resp) {
        setUploadLoading(false);
        setLength(0);
        setDocumentList([]);
        toast.success(getValue(resp, `message`, ""));
        getData(getValue(params, `id`, ""));
      } else {
        setLength(0);
        setDocumentList([]);
        setUploadLoading(false);
      }
    } catch (error) {
      setLength(0);
      setDocumentList([]);
      setUploadLoading(false);
    }
  };
  /* ------------------------------- Delete Section -------------------------- */
  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteFun = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };
  const handleDeleteFunction = async () => {
    try {
      let resp = await deleteExpenseEntryReceipts(
        getValue(params, `id`, ""),
        deleteId
      );
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getData(getValue(params, `id`, ""));
      }
    } catch (error) {}
  };

  /* ------------------------------- Delete Expense Section -------------------------- */

  const [deleteExpenseValue, setDeleteExpenseValue] = useState("");
  const [deleteExpenseId, setDeleteExpenseId] = useState("");
  const handleExpenseDeleteFun = (id: string, value: string) => {
    setDeleteExpenseValue(value);
    setDeleteExpenseId(id);
    handleExpenseDelete();
  };
  const [isExpenseDelete, setIsExpenseDelete] = useState(false);
  const handleExpenseDelete = () => {
    setIsExpenseDelete(!isExpenseDelete);
  };
  const handleDeleteExpenseFunction = async () => {
    try {
      let resp = await deleteSpecificExpenseEntry(deleteExpenseId);
      if (resp) {
        navigate(`/client/expenses`);
        toast.success(getValue(resp, `message`, ""));
        handleExpenseDelete();
      }
    } catch (error) {}
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    // const formValid = simpleValidator.current.allValid();
    // if (!formValid) {
    //   simpleValidator.current.showMessages();
    //   forceUpdate(1);
    // } else {
    // try {
    //   setSubmitLoading(true);
    //   // let fields = JSON.parse(JSON.stringify(form));
    //   // let payload = FormRequestHelper(fields);
    //   let payload = {};
    //   let requestPayload: any = payload;
    //   requestPayload["receipt_ids"] =
    //     getValue(documents, `length`, 0) > 0
    //       ? documents.map((item: object) => getValue(item, `id`, ""))
    //       : [];
    //   let resp = await updateExpenseEntry(
    //     getValue(params, `id`, ""),
    //     removeNullOrUndefinedProperties(requestPayload)
    //   );
    //   if (resp) {
    //     toast.success(getValue(resp, `message`, ""));
    //     // navigate(`/client/expenses`);
    //     getData(getValue(params, `id`, ""));
    //     setSubmitLoading(false);
    //   } else {
    //     setSubmitLoading(false);
    //   }
    // } catch (error) {
    //   setSubmitLoading(false);
    // }
    // }
  };
  /* -------------------------------------------------------------------------- */
  /*                              Comment section                               */
  /* -------------------------------------------------------------------------- */
  const [commentLoading, setCommentLoading] = useState(false);
  const [allComments, setAllComments] = useState([]);
  const getComment = async () => {
    try {
      let payload = {};
      setCommentLoading(true);
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllExpenseEntryComments(
        getValue(params, `id`, ""),
        queryRequest
      );
      if (resp) {
        setAllComments(getValue(resp, `data.comments`, []));
        setCommentLoading(false);
      } else {
        setCommentLoading(false);
      }
    } catch (error) {
      setCommentLoading(false);
    }
  };
  const [comments, setComments] = useState("");
  const handleAddComment = async () => {
    try {
      let payload = {
        comments: comments,
        type: "standard",
      };
      let resp = await createExpenseEntryComment(
        getValue(params, `id`, ""),
        payload
      );
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setComments("");
        getComment();
      }
    } catch (error) {}
  };

  const [commentDelete, setCommentDelete] = useState(false);
  const [commentDeleteValue, setCommentDeleteValue] = useState("");
  const [commentDeleteId, setCommentDeleteId] = useState("");
  const handleDeleteComment = (id: string, value: string) => {
    setCommentDeleteId(id);
    setCommentDeleteValue(value);
    setCommentDelete(!commentDelete);
  };
  const handleSubmitCommentDelete = async () => {
    try {
      let resp = await deleteExpenseEntryComment(commentDeleteId);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        getComment();
        setCommentDeleteValue("");
        setCommentDeleteId("");
        setCommentDelete(!commentDelete);
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                            Image Preview section                           */
  /* -------------------------------------------------------------------------- */
  const lightGallery = useRef<any>(null);
  const handleClickGallery = (item: any) => {
    if (getValue(item, `receipts.length`, 0) > 0) {
      let list = getValue(item, `receipts`, []).map((item: any) => ({
        id: getValue(item, `id`, ""),
        size: "1400-933",
        src: getValue(item, `url`, ""),
        thumb: getValue(item, `url`, ""),
        subHtml: `<div class="lightGallery-captions">
                  <h4><a href="">${getValue(item, `name`, "")}</a></h4>
              </div>`,
      }));
      setImageList(list);
    }
  };
  const [imageList, setImageList] = useState([]);
  const openGallery = useCallback(() => {
    lightGallery.current.openGallery();
  }, []);
  const onInit = useCallback((detail: any) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                           Expense Delete section                           */
  /* -------------------------------------------------------------------------- */

  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes("expenses") ? (
        <NoAccessPage />
      ) : (
        <>
          <StickyHeader
            handleSubmit={handleSubmit}
            submitLoading={submitLoading}
            route={`/client/expenses`}
            title={"Expense Form"}
            handleEdit={() =>
              navigate(`/client/expenses/edit/${getValue(params, `id`, "")}`)
            }
            formInfo={formInfo}
            handleDeleteFun={handleExpenseDeleteFun}
            permissions={getValue(props, `permissions`, [])}
          />
          <div className="expense-detail-wrapper_container">
            <div className="expense-detail-wrapper_right">
              <ExpenseHeaderRightSection formInfo={formInfo} />
              {isLoading || !props.permissionAPITriggered ? (
                <Loader />
              ) : (
                <div className="expense-detail-wrapper_right_detail_section">
                  <div className="expense-detail-wrapper_right_container">
                    {getValue(formInfo, `policy_violations.length`, 0) > 0 && (
                      <ExpenseViolationSection formInfo={formInfo} />
                    )}
                    <ExpenseAmountSection formInfo={formInfo} />
                    <ExpenseDetailSection formInfo={formInfo} />
                    <ExpenseTabNavigation
                      activeTab={activeTab}
                      handleActiveTab={handleActiveTab}
                    />
                    <DetailContainer
                      activeTab={activeTab}
                      formInfo={formInfo}
                      handleAddComment={handleAddComment}
                      comments={comments}
                      setComments={setComments}
                      allComments={allComments}
                      commentLoading={commentLoading}
                      handleDeleteComment={handleDeleteComment}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="expense-detail-wrapper_left">
              <div className="expense-detail-wrapper_left_container">
                <ExpenseDocumentSection
                  documents={documents}
                  handleUploadDocuments={handleUploadDocuments}
                  uploadLoading={uploadLoading || uploadStaticLoading}
                  handleRemoveDocuments={handleDeleteFun}
                  openGallery={openGallery}
                />
              </div>
            </div>
          </div>
          <DeleteModal
            isOpen={isDelete}
            handleModal={handleDelete}
            handleSubmit={handleDeleteFunction}
            deleteValue={deleteValue}
          />
          <DeleteModal
            isOpen={isExpenseDelete}
            handleModal={handleExpenseDelete}
            handleSubmit={handleDeleteExpenseFunction}
            deleteValue={deleteExpenseValue}
          />
          <DeleteModal
            isOpen={commentDelete}
            handleModal={handleDelete}
            handleSubmit={handleSubmitCommentDelete}
            deleteValue={commentDeleteValue}
          />
          {getValue(imageList, `length`, 0) > 0 && (
            <LightGalleryPreview onInit={onInit} imageList={imageList} />
          )}
        </>
      )}
    </DashboardLayout>
  );
}

export default ClientExpenseDetail;

import { getValue } from "@utils/lodash";
import React from "react";
import Input from "../Forms/Input";
import {
  formHandleBooleanArrayChange,
  formHandleDateArrayChange,
  formHandleLookeupArrayChange,
  formHandleNumericArrayChange,
  formHandleTextArrayChange,
  formHandleTimeArrayChange,
} from "@common/handleChange";
import DateMonthYearPicker from "../Forms/Date";
import TextArea from "../Forms/textarea";
import { capitalizeFirstLetter } from "@common/text-helpers";
import TimePicker from "../Forms/Time";
import "./form-builder.scss";
import Radio from "../Forms/Radio";
import Checkbox from "../Forms/Checkbox";
import { generateFieldOptions } from "./generate-options";
import SingleSelectSearchDropdown from "../CustomSearchableDropdown/SingleSelectSearchDropdown";
import { getLookUpIdByName } from "./request-helper";

function FormBuilder(props: any) {
  const { setFields, fields, simpleValidator, formOptions } = props;

  const getMinDate = (name: string) => {
    let duration_from =
      getValue(fields, `length`, 0) > 0
        ? fields.filter(
            (item: object) =>
              getValue(item, `field_name`, "") === "duration_from"
          )
        : [];
    switch (name) {
      case "duration_to":
        return getValue(duration_from, `[${0}].field_value`, "")
          ? new Date(getValue(duration_from, `[${0}].field_value`, ""))
          : null;
      default:
        return null;
    }
  };

  const renderForm = (item: any, index: number, formOptions: any) => {
    switch (
      getValue(item, `data_type`, "") +
      "-" +
      getValue(item, `sub_data_type`, "")
    ) {
      case "text-single_line_text":
        return (
          <div className="form_builder_field">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                formHandleTextArrayChange(index, e, setFields)
              }
              value={getValue(item, `field_value`, "")}
              type={"text"}
              placeholder={`Enter ${getValue(item, `display_name`, "")}`}
              label={`${capitalizeFirstLetter(
                getValue(item, `display_name`, "")
              )}`}
              validator={
                getValue(item, `is_mandatory`, false) && simpleValidator
              }
              mandatory={getValue(item, `is_mandatory`, false)}
            />
          </div>
        );
      case "radio-boolean":
        return (
          <div className="form_builder_field">
            <Radio
              {...item}
              onChange={(e: any) =>
                formHandleBooleanArrayChange(
                  index,
                  e,
                  setFields,
                  getValue(item, `field_value`, "") === true ? false : true
                )
              }
              value={getValue(item, `field_value`, "")}
              checked={getValue(item, `field_value`, "") ? true : false}
              type={getValue(item, `data_type`, "")}
              placeholder={`Enter ${getValue(item, `display_name`, "")}`}
              label={`${capitalizeFirstLetter(
                getValue(item, `display_name`, "")
              )}`}
              validator={
                getValue(item, `is_mandatory`, false) && simpleValidator
              }
              mandatory={getValue(item, `is_mandatory`, false)}
            />
          </div>
        );
      case "checkbox-boolean":
        return (
          <div className="form_builder_field">
            <Checkbox
              {...item}
              onChange={(e: any) =>
                formHandleBooleanArrayChange(
                  index,
                  e,
                  setFields,
                  getValue(item, `field_value`, "") === true ? false : true
                )
              }
              value={getValue(item, `field_value`, "")}
              checked={getValue(item, `field_value`, "") ? true : false}
              type={getValue(item, `data_type`, "")}
              placeholder={`Enter ${getValue(item, `display_name`, "")}`}
              label={`${capitalizeFirstLetter(
                getValue(item, `display_name`, "")
              )}`}
              validator={
                getValue(item, `is_mandatory`, false) && simpleValidator
              }
              mandatory={getValue(item, `is_mandatory`, false)}
            />
          </div>
        );
      case "text-multi_line_text":
        return (
          <div className="form_builder_field">
            <TextArea
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                formHandleTextArrayChange(index, e, setFields)
              }
              value={getValue(item, `field_value`, "")}
              type={"textarea"}
              label={`${getValue(item, `display_name`, "")}`}
              placeholder={`Enter ${getValue(item, `display_name`, "")}`}
              rows={5}
              validator={
                getValue(item, `is_mandatory`, false) && simpleValidator
              }
              mandatory={getValue(item, `is_mandatory`, false)}
            />
          </div>
        );
      case "numerical-single_line_text":
        return (
          <div className="form_builder_field">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                formHandleNumericArrayChange(index, e, setFields)
              }
              value={getValue(item, `field_value`, "")}
              type={"number"}
              placeholder={`Enter ${getValue(item, `display_name`, "")}`}
              label={`${getValue(item, `display_name`, "")}`}
              validator={
                getValue(item, `is_mandatory`, false) && simpleValidator
              }
              mandatory={getValue(item, `is_mandatory`, false)}
            />
          </div>
        );
      case "numerical-percentage":
        return (
          <div className="form_builder_field">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                formHandleNumericArrayChange(index, e, setFields)
              }
              value={getValue(item, `field_value`, "")}
              type={"number"}
              placeholder={`Enter ${getValue(item, `display_name`, "")}`}
              label={`${getValue(item, `display_name`, "")}`}
              validator={
                getValue(item, `is_mandatory`, false) && simpleValidator
              }
              mandatory={getValue(item, `is_mandatory`, false)}
            />
          </div>
        );
      case "numerical-decimal":
        return (
          <div className="form_builder_field">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                formHandleNumericArrayChange(index, e, setFields)
              }
              value={getValue(item, `field_value`, "")}
              type={"number"}
              placeholder={`Enter ${getValue(item, `display_name`, "")}`}
              label={`${getValue(item, `display_name`, "")}`}
              validator={
                getValue(item, `is_mandatory`, false) && simpleValidator
              }
              mandatory={getValue(item, `is_mandatory`, false)}
            />
          </div>
        );
      case "numerical-number":
        return (
          <div className="form_builder_field">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                formHandleNumericArrayChange(index, e, setFields)
              }
              value={getValue(item, `field_value`, "")}
              type={"number"}
              placeholder={`Enter ${getValue(item, `display_name`, "")}`}
              label={`${getValue(item, `display_name`, "")}`}
              validator={
                getValue(item, `is_mandatory`, false) && simpleValidator
              }
              mandatory={getValue(item, `is_mandatory`, false)}
            />
          </div>
        );

      case "email-email":
        return (
          <div className="form_builder_field">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                formHandleTextArrayChange(index, e, setFields)
              }
              value={getValue(item, `field_value`, "")}
              type={"email"}
              placeholder={`Enter ${getValue(item, `display_name`, "")}`}
              label={`${getValue(item, `display_name`, "")}`}
              validator={
                getValue(item, `is_mandatory`, false) && simpleValidator
              }
              validatoryType={
                getValue(item, `required`, false) && "required|email"
              }
              mandatory={getValue(item, `is_mandatory`, false)}
            />
          </div>
        );
      case "phone-phone":
        return (
          <div className="form_builder_field">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                formHandleTextArrayChange(index, e, setFields)
              }
              type={"text"}
              placeholder={`Enter ${getValue(item, `display_name`, "")}`}
              label={`${getValue(item, `display_name`, "")}`}
              validator={
                getValue(item, `is_mandatory`, false) && simpleValidator
              }
              validatoryType={
                getValue(item, `required`, false) && "required|min:10|max:10"
              }
              mandatory={getValue(item, `is_mandatory`, false)}
            />
          </div>
        );
      case "url-url":
        return (
          <div className="form_builder_field">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                formHandleTextArrayChange(index, e, setFields)
              }
              value={getValue(item, `field_value`, "")}
              type={"url"}
              placeholder={`Enter ${getValue(item, `display_name`, "")}`}
              label={`${getValue(item, `display_name`, "")}`}
              validator={
                getValue(item, `is_mandatory`, false) && simpleValidator
              }
              mandatory={getValue(item, `is_mandatory`, false)}
            />
          </div>
        );
      case "date-date":
        return (
          <div className="form_builder_field">
            <DateMonthYearPicker
              {...item}
              onChange={(e: any) =>
                formHandleDateArrayChange(index, e, setFields)
              }
              value={
                getValue(item, `field_value`, "")
                  ? getValue(item, `field_value`, "")
                  : null
              }
              type={"text"}
              label={`${getValue(item, `display_name`, "")}`}
              placeholder={`Enter ${getValue(item, `display_name`, "")}`}
              validator={
                getValue(item, `is_mandatory`, false) && simpleValidator
              }
              // minDate={new Date()}
              mandatory={getValue(item, `is_mandatory`, false)}
              maxDate={new Date()}
              minDate={getMinDate(getValue(item, `field_name`, ""))}
            />
          </div>
        );
      case "date-date_time":
        return (
          <div className="form_builder_field">
            <TimePicker
              {...item}
              onChange={(e: any) =>
                formHandleTimeArrayChange(index, e, setFields)
              }
              value={
                getValue(item, `field_value`, "")
                  ? getValue(item, `field_value`, "")
                  : null
              }
              type={"text"}
              label={`${getValue(item, `display_name`, "")}`}
              placeholder={`Enter ${getValue(item, `display_name`, "")}`}
              validator={
                getValue(item, `is_mandatory`, false) && simpleValidator
              }
              showTimeInput
              mandatory={getValue(item, `is_mandatory`, false)}
            />
          </div>
        );
      case "picklist-single_select":
        return (
          <div className="form_builder_field">
            <SingleSelectSearchDropdown
              {...item}
              placeholder={`Enter ${getValue(item, `display_name`, "")}`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                formHandleLookeupArrayChange(index, e, setFields)
              }
              label={`label`}
              validator={
                getValue(item, `is_mandatory`, false) && simpleValidator
              }
              width={"100%"}
              data={generateFieldOptions(
                getValue(item, `field_name`, ""),
                getValue(
                  formOptions,
                  `[${getValue(item, `field_name`, "")}]`,
                  []
                )
              )}
              value={getValue(item, `field_value`, "")}
              selectKey={"id"}
              name={getValue(item, `display_name`, "")}
              mandatory={getValue(item, `is_mandatory`, false)}
              showLabel
            />
          </div>
        );
      case "lookup-single_select":
        return (
          <div className="form_builder_field">
            <SingleSelectSearchDropdown
              {...item}
              placeholder={`Enter ${getValue(item, `display_name`, "")}`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                formHandleLookeupArrayChange(index, e, setFields)
              }
              lookup_id={getLookUpIdByName(item)}
              label={`label`}
              validator={
                getValue(item, `is_mandatory`, false) && simpleValidator
              }
              width={"100%"}
              value={getValue(item, `field_value`, "")}
              name={getValue(item, `display_name`, "")}
              lookup_api={getValue(item, `field_name`, "")}
              mandatory={getValue(item, `is_mandatory`, false)}
              showLabel
            />
          </div>
        );
      case "lookup-currency":
        return (
          <div className="form_builder_field">
            <SingleSelectSearchDropdown
              {...item}
              placeholder={`Enter ${getValue(item, `display_name`, "")}`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                formHandleLookeupArrayChange(index, e, setFields)
              }
              label={`label`}
              validator={
                getValue(item, `is_mandatory`, false) && simpleValidator
              }
              width={"100%"}
              value={getValue(item, `field_value`, "")}
              name={getValue(item, `display_name`, "")}
              lookup_api={getValue(item, `field_name`, "")}
              mandatory={getValue(item, `is_mandatory`, false)}
              showLabel
            />
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div>
      {getValue(fields, `length`, 0) > 0 &&
        fields.map((item: any, key: number) => {
          return (
            <div className={`form_wrapper-pipeline`} key={key}>
              {renderForm(item, key, formOptions)}
            </div>
          );
        })}
    </div>
  );
}

export default FormBuilder;

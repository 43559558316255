import "./Header.scss";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import { clientLogout } from "@services/auth.service";
import { useNavigate } from "react-router-dom";
import MyViewSvgComponent from "@assets/svg-components/my-view";
import AdminViewSvgComponent from "@assets/svg-components/admin-view";
import { getAllOrganisation } from "@services/organisation.service";
import { formatText } from "@common/text-helpers";
import { useNavigationData } from "../LeftNavbar/NavigationContext";
import PersonSvgComponent from "@assets/svg-components/person";
import { getColorStyle } from "@common/color";
import UsercontrolSvgComponent from "@assets/svg-components/sidebar/usercontrol";
import { Tooltip } from "react-tooltip";
import CloseSmallSvgComponent from "@assets/svg-components/close-small";

export default function Header() {
  const navigate = useNavigate();
  const handleLogout = async () => {
    // let payload = {
    // 	refresh_token: getValue(localStorage, `refreshToken`, ""),
    // };
    // let resp = await clientLogout(payload);
    // localStorage.removeItem("accessToken");
    // localStorage.removeItem("user");
    // window.location.href = `/login`;
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    window.location.href = `/login`;
    let payload = {
      refresh_token: getValue(localStorage, `refreshToken`, ""),
    };
    let resp = await clientLogout(payload);
  };
  const handleChangeView = async (view: string) => {
    if (view === "admin") {
      localStorage.setItem("view", "admin");
      window.location.href = `/admin/dashboard`;
    } else {
      localStorage.setItem("view", "client");
      window.location.href = `/`;
    }
  };
  const handleNavigateHome = (view: string) => {
    if (view === "admin") {
      navigate(`/admin/dashboard`);
    } else {
      navigate(`/`);
    }
  };
  useEffect(() => {
    if (!getValue(localStorage, `organisation_logo`, "")) {
      getData();
    }
  }, []);
  const getData = async () => {
    try {
      let resp = await getAllOrganisation("");
      if (resp) {
        if (
          getValue(resp, `data.organisations[${0}].organisation_logo.url`, "")
        ) {
          localStorage.setItem(
            "organisation_logo",
            getValue(resp, `data.organisations[${0}].organisation_logo.url`, "")
          );
        }
      }
    } catch (error) {}
  };

  const { info, user } = useNavigationData();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <header className="header-wrapper d-flex align-items-center">
      <div className="container-fluid d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center cursor-pointer">
          <a
            onClick={() =>
              handleNavigateHome(getValue(localStorage, `view`, ""))
            }
            className="d-inline-block"
          >
            <img
              src={
                getValue(localStorage, `organisation_logo`, "")
                  ? getValue(localStorage, `organisation_logo`, "")
                  : "/images/logo.png"
              }
              // src="/images/demo.svg"
              width="100%"
              height="100%"
              className="img-fluid header_logo"
            />
          </a>
        </div>
        {getValue(localStorage, `user`, "") && (
          <div className="d-flex align-items-center">
            {getValue(localStorage, `view`, "") !== "client" && (
              <div
                className="view_header  p-2 px-2 mx-3 border-radius__10 w-auto"
                style={getColorStyle("rejected")}
              >
                <UsercontrolSvgComponent size={20} color={"#d72e38"} />
                <p className="ms-1 pt-1 small_text__15 color_danger">
                  {formatText(getValue(localStorage, `view`, "")) +
                    " " +
                    "View"}
                </p>
              </div>
            )}
            {getValue(info, `is_admin`, false) && (
              <div className="switch-dropdown">
                {/* <UncontrolledDropdown> */}
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle>
                    <div className="switch_header__dropdown m-0">
                      <div
                        className="dropdown-content d-flex cursor-pointer"
                        data-tooltip-id="switchTooltip"
                      >
                        <img
                          src="/images/switch.svg"
                          // width="173"
                          // height="52"
                          className="img-fluid"
                        />
                        <Tooltip id="switchTooltip" content="Switch to" />
                      </div>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu>
                    <div className="switch-dropdown-item-header">
                      <p>Switch to</p>
                      <span onClick={toggle} className="close-svg">
                        {/* <CloseSmallSvgComponent />  */}
                        <img src="/images/close.svg" width={`18px`} />
                      </span>
                    </div>
                    <DropdownItem onClick={() => handleChangeView("client")}>
                      <div
                        className={`switch-dropdown-item ${
                          getValue(localStorage, `view`, "") !== "admin"
                            ? "active"
                            : ""
                        }`}
                      >
                        <p className="">My View</p>
                        <MyViewSvgComponent
                          color={
                            getValue(localStorage, `view`, "") === "admin"
                              ? ""
                              : "#d7242e"
                          }
                          size={20}
                        />
                      </div>
                    </DropdownItem>
                    <DropdownItem onClick={() => handleChangeView("admin")}>
                      <div
                        className={`switch-dropdown-item ${
                          getValue(localStorage, `view`, "") === "admin"
                            ? "active"
                            : ""
                        }`}
                      >
                        <p className="">Admin View</p>
                        <AdminViewSvgComponent
                          color={
                            getValue(localStorage, `view`, "") === "client"
                              ? ""
                              : "#d7242e"
                          }
                          size={20}
                        />
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                {/* </UncontrolledDropdown> */}
              </div>
            )}
            <div className="bulk-dropdown user-dropdown">
              <UncontrolledDropdown>
                <DropdownToggle>
                  <div className="header__dropdown m-0">
                    <div className="dropdown-content d-flex cursor-pointer">
                      <span className="m-auto initial-holder">
                        {getValue(user, `email`, "").charAt(0).length > 0
                          ? getValue(user, `email`, "").charAt(0).toUpperCase()
                          : "A"}
                      </span>
                    </div>
                  </div>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>
                    <h4>Accounts</h4>
                  </DropdownItem>

                  <div className="user-dropdown-body">
                    <DropdownItem className="profile-icon-circle">
                      <div className="header__dropdown m-0  text-center d-flex">
                        <div className="dropdown-content d-flex cursor-pointer profile-circle">
                          <span className="m-auto profile-initial">
                            {getValue(user, `email`, "").charAt(0).length > 0
                              ? getValue(user, `email`, "")
                                  .charAt(0)
                                  .toUpperCase()
                              : "A"}
                          </span>
                        </div>
                      </div>
                    </DropdownItem>
                    <DropdownItem className="user-dd-details-wrapper">
                      <>
                        <p className="text-left user-name">
                          {getValue(user, `first_name`, "")}{" "}
                          {getValue(user, `middle_name`, "")}{" "}
                          {getValue(user, `last_name`, "")}
                        </p>
                        <p className="text-left user-email">{getValue(user, `email`, "")}</p>
                        {/* <button className="header-btn">Ascent Account</button> */}
                      </>
                    </DropdownItem>
                  </div>
                  {/* <DropdownItem divider /> */}

                  <DropdownItem className="pb-0">
                    <div className="text-center user-holder-button">
                      <button
                        className="ascent-button ascent-button--secondary ascent-button--header-buttons"
                        onClick={handleLogout}
                      >
                        Sign out
                      </button>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

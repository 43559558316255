import AddSvgComponent from "@assets/svg-components/add";
import ViolationSvgComponent from "@assets/svg-components/violation";
import { convertCurrentDate } from "@common/date-helpers";
import { formatAmount, formatText } from "@common/text-helpers";
import NotfoundSmall from "@components/Not-Found/not-found-small";
import { getValue } from "@utils/lodash";
import { Tooltip } from "react-tooltip";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledAccordion,
  UncontrolledDropdown,
  Dropdown,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useCallback, useEffect, useRef, useState } from "react";
import LightGalleryPreview from "@common/lightgallery-preview";
import EyeSvgComponent from "@assets/svg-components/eye";
import _ from "lodash";
import ArrowDownSvgComponent from "@assets/svg-components/arrow-down";
import LineArrowDownSvgComponent from "@assets/svg-components/line-arrow-down";
import moment from "moment-timezone";
import { handleDownloadOnClick } from "@common/downloadfile";
import DocumentSvgComponent from "@assets/svg-components/document";

function ReportExpenseListSection(props) {
  const { list, handleDeleteFun } = props;
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = () => {
    setIsHovered(true);
  };
  const handleHoverExit = () => {
    setIsHovered(false);
  };

  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    if (getValue(localStorage, `user`, {}))
      setUserInfo(JSON.parse(getValue(localStorage, `user`, {})));
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                            Image Preview section                           */
  /* -------------------------------------------------------------------------- */
  const lightGallery = useRef(null);
  const handleClickGallery = (item) => {
    let list = getValue(item, `receipts`, []).map((item) => ({
      id: getValue(item, `id`, ""),
      size: "1400-933",
      src: getValue(item, `url`, ""),
      thumb: getValue(item, `url`, ""),
      subHtml: `<div class="lightGallery-captions">
                  <h4><a href="">${getValue(item, `name`, "")}</a></h4>
              </div>`,
    }));
    setImageList(list);
  };
  const [imageList, setImageList] = useState([]);
  const openGallery = useCallback((index) => {
    lightGallery.current.openGallery(index);
  }, []);
  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  const [modalReadMore, setModalReadMore] = useState(false);
  const toggleReadMore = () => setModalReadMore(!modalReadMore);

  const [modalInfo, setModalInfo] = useState([]);
  const getModalData = (obj) => {
    setModalInfo(obj);
    toggleReadMore();
  };

  const formatDate = (inputDate) => {
    // const parsedDate = moment(inputDate);
    const parsedDate = moment(inputDate, ["DD-MM-YYYY", "YYYY-MM-DD"], true);
    // Check if the parsed date is valid
    if (parsedDate.isValid()) {
      // Format the date to "DD MMM YYYY" format
      const formattedDate = parsedDate.format("DD MMM YYYY");
      // console.log(formattedDate); // Output: 29 Feb 2024
      return formattedDate;
    } else {
      return inputDate;
    }
  };

  return (
    <div
      className={`${
        props.hideExpenseHeader
          ? getValue(props, `formInfo.approval_status`, "") === "reimbursed"
            ? "report-detail-wrapper_left_container_approval_list_section_reimbursement"
            : "report-detail-wrapper_left_container_approval_list_section"
          : "report-detail-wrapper_left_container_list_section"
      } position-relative expense-details-left-card-list-item report-detail-wrapper_left_container_list_section mt-16`}
    >
      {getValue(list, `length`, 0) > 0 ? (
        list.map((item, index) => {
          const expenseEntryData = getValue(item, "expense_entry_cf", {});
          // Extract non-null key-value pairs into an array of objects
          const data = _.chain(expenseEntryData)
            .omitBy(_.isNull)
            .map((value, label) => ({ value, label }))
            .value();

          // console.log(item);
          return (
            <UncontrolledAccordion defaultOpen="1">
              <div
                className={`report-detail-wrapper_left_container_list_item w-100`}
                key={index}
              >
                <div className="d-flex justify-content-between align-items-center report-detail-wrapper-left-list-holder">
                  <div className="expense-detail-wrapper_report_infosection d-flex">
                    <div className="expense-detail-wrapper_report d-flex align-items-center">
                      <div className="">
                        <h5 className="header_text">
                          {getValue(item, `expense_no`, "")}
                        </h5>
                        <p className="desc_text">Expense No</p>
                      </div>
                      {/* <div className="vertical_line"></div> */}
                    </div>

                    <div className="expense-detail-wrapper_report d-flex align-items-center">
                      <div className="">
                        <h5 className="header_text">
                          {convertCurrentDate(
                            getValue(item, `expense_date`, "")
                          )}
                        </h5>
                        <p className="desc_text">Expense Date</p>
                      </div>
                      {/* <div className="vertical_line"></div> */}
                    </div>
                    <div className="expense-detail-wrapper_report d-flex align-items-center">
                      <div className="">
                        <h5 className="header_text">
                          {getValue(item, `expense_type.expense_type_name`, "")}
                        </h5>
                        <p className="desc_text">Type</p>
                      </div>
                      {/* <div className="vertical_line"></div> */}
                    </div>

                    {getValue(item, `expense_entry_mileage.distance`, "") && (
                      <>
                        <div className="expense-detail-wrapper_report d-flex align-items-center">
                          <div className="">
                            <h5 className="header_text">
                              {getValue(
                                item,
                                `expense_entry_mileage.distance`,
                                ""
                              )}{" "}
                              {getValue(
                                item,
                                `expense_entry_mileage.mileage_unit`,
                                ""
                              )}
                            </h5>
                            <p className="desc_text">Distance</p>
                          </div>
                          {/* <div className="vertical_line"></div> */}
                        </div>
                        <div className="expense-detail-wrapper_report d-flex align-items-center">
                          <div className="">
                            <h5 className="header_text">
                              {getValue(
                                item,
                                `expense_entry_mileage.vehicle.vehicle_name`,
                                ""
                              )}
                            </h5>
                            <p className="desc_text">Vehicle Type</p>
                          </div>
                          {/* <div className="vertical_line"></div> */}
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    <p className="header_text__18 color_danger text-right">
                      {getValue(item, `claimed_currency_symbol`, "")}{" "}
                      {getValue(item, `amount`, "")}
                    </p>
                    {getValue(item, `bc_currency`, "") !==
                      getValue(item, `claimed_currency`, "") && (
                      <p className="small_text mt-1">
                        Amount in {getValue(item, `bc_currency`, "")}
                        {": "}
                        {getValue(item, `bc_amount`, "")} {"( "}1{" "}
                        {getValue(item, `claimed_currency_symbol`, "")} ={" "}
                        {getValue(item, `conversion_rate`, "")}{" "}
                        {getValue(item, `bc_currency`, "")}
                        {" )"}
                      </p>
                    )}
                  </div>
                </div>

                <div className="reports-details-dashed-border"></div>

                <div className="d-flex align-items-center justify-content-between report-detail-wrapper-left-list-holder mb-16">
                  <div className="expense-detail-wrapper_report_infosection d-flex">
                    <div className="expense-detail-wrapper_report d-flex align-items-center">
                      <div className="">
                        <h5 className="header_text">
                          {" "}
                          {formatText(
                            getValue(item, `paid_through.account_name`, "")
                          )}
                        </h5>
                        <p className="desc_text">Paid Through</p>
                      </div>
                      {/* <div className="vertical_line"></div> */}
                    </div>
                    <div className="expense-detail-wrapper_report d-flex align-items-center">
                      <div className="">
                        <h5 className="header_text">
                          {getValue(item, `referance_no`, "")
                            ? getValue(item, `referance_no`, "")
                            : "-"}
                        </h5>
                        <p className="desc_text">Reference</p>
                      </div>
                      {/* <div className="vertical_line"></div> */}
                    </div>
                    <div className="expense-detail-wrapper_report d-flex align-items-center">
                      <div className="">
                        <h5 className="header_text">
                          {formatText(getValue(item, `status`, ""))}
                        </h5>
                        <p className="desc_text">Status</p>
                      </div>
                      {/* <div className="vertical_line"></div> */}
                    </div>

                    {/* <div className="expense-detail-wrapper_report d-flex align-items-center view-more-dd-holder">
                      {getValue(data, `length`, 0) > 0 && (
                        <AccordionItem
                          className={`report-detail-list-action-icons-holder`}
                        >
                          <AccordionHeader targetId={`moreInfo`}>
                            <div
                              data-tooltip-id={`my-info${index}`}
                              data-tooltip-content="More Info"
                            >
                              <EyeSvgComponent color={"#dcdcdc"} size={24} />
                              View more info
                              <Tooltip id={`my-info${index}`} />
                            </div>
                          </AccordionHeader>
                        </AccordionItem>
                      )}
                    </div> */}

                    {/* <div className="expense-detail-wrapper_report d-flex align-items-center view-more-dd-holder">
                      <h5
                        className="header_text"
                        onClick={() => getModalData(data)}
                      >
                        View more info
                      </h5>
                    </div> */}

                    {getValue(data, `length`, 0) > 0 && (
                      <div className="expense-detail-wrapper_report d-flex align-items-center view-more-dd-holder">
                        <h5
                          className="header_text"
                          onClick={() => getModalData(data)}
                        >
                          View more info
                        </h5>
                      </div>
                    )}
                  </div>
                  <div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center report-detail-list-action-icons-wrapper">
                        {/* {getValue(data, `length`, 0) > 0 && (
                          <AccordionItem className={`report-detail-list-action-icons-holder`}>
                            <AccordionHeader targetId={`moreInfo`}>
                              <div
                                data-tooltip-id={`my-info${index}`}
                                data-tooltip-content="More Info"
                                
                              >
                                <EyeSvgComponent color={"#dcdcdc"} size={24} />
                                <Tooltip id={`my-info${index}`} />
                              </div>
                            </AccordionHeader>
                          </AccordionItem>
                        )} */}
                        {/* <AccordionItem>
                          <AccordionHeader targetId={`violation`}> */}
                        <div
                          data-tooltip-id={
                            getValue(item, `policy_violations.length`, "") === 0
                              ? ""
                              : `my-tooltip${index}`
                          }
                          data-tooltip-content="This expense violated certain policies"
                          className={`report-detail-list-action-icons-holder`}
                        >
                          <ViolationSvgComponent
                            color={
                              getValue(item, `policy_violations.length`, "") > 0
                                ? "#ff8a00"
                                : "lightgray"
                            }
                            size={24}
                          />
                          <Tooltip id={`my-tooltip${index}`} />
                        </div>
                        {/* </AccordionHeader>
                        </AccordionItem> */}
                        {/* <div className="close-image_container ms-2"></div> */}
                        {getValue(userInfo, `id`, "") ==
                          getValue(props, `formInfo.created_by.id`, "") &&
                          (getValue(props, `formInfo.approval_status`, "") ===
                            "awaiting_approval" ||
                            getValue(props, `formInfo.approval_status`, "") ===
                              "unsubmitted" ||
                            getValue(props, `formInfo.approval_status`, "") ===
                              "recalled") && (
                            <UncontrolledDropdown className="action-icons-main-holder">
                              <DropdownToggle>
                                <div className="switch_header__dropdown m-0 align-items-center">
                                  <div className="close-image_container action-icon-wrap">
                                    <img
                                      src={"/images/more.svg"}
                                      className=" close_image"
                                    />
                                  </div>
                                </div>
                              </DropdownToggle>
                              <DropdownMenu className="mt-4">
                                {(getValue(
                                  props,
                                  `formInfo.approval_status`,
                                  ""
                                ) === "unsubmitted" ||
                                  getValue(
                                    props,
                                    `formInfo.approval_status`,
                                    ""
                                  ) === "recalled") && (
                                  <DropdownItem
                                    onClick={() =>
                                      getValue(
                                        props,
                                        `formInfo.approval_status`,
                                        ""
                                      ) === "unsubmitted" ||
                                      getValue(
                                        props,
                                        `formInfo.approval_status`,
                                        ""
                                      ) === "recalled"
                                        ? props.handleEditExpense(
                                            getValue(item, `id`, "")
                                          )
                                        : null
                                    }
                                  >
                                    <div className={`more-dropdown-main-item `}>
                                      <p>Edit</p>
                                    </div>
                                  </DropdownItem>
                                )}
                                <DropdownItem
                                  onClick={() =>
                                    handleDeleteFun(
                                      getValue(item, `id`, ""),
                                      ""
                                    )
                                  }
                                >
                                  <div className={`more-dropdown-main-item `}>
                                    <p>Delete</p>
                                  </div>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* {getValue(item, `description`, "") && ( */}
                <div className="expense-details-card-desc-wrap text-center">
                  <div
                    style={{ background: "#E8F0FE" }}
                    className="expense-details-card-desc"
                  >
                    <p style={{ color: "#204891" }} className="small_text">
                      Description: &nbsp;
                      {getValue(item, `description`, "")
                        ? getValue(item, `description`, "")
                        : "No description added"}
                    </p>
                  </div>
                </div>
                {/* )} */}
                {getValue(item, `receipts.length`, 0) > 0 ? (
                  <div>
                    {/* <div className="border-dotted mb-3"></div> */}
                    <div className="d-flex flex-wrap mb-3 ">
                      {getValue(item, `receipts`, []).map((doc, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              const receiptsLength = getValue(
                                item,
                                "receipts.length",
                                0
                              );
                              const approvalStatus = getValue(
                                props,
                                "formInfo.approval_status",
                                ""
                              );
                              if (receiptsLength > 0) {
                                // handleClickGallery(item);
                                // setTimeout(() => {
                                //   openGallery(index);
                                // }, 500);
                                // handleDownloadOnClick(
                                //   getValue(item, `url`, "")
                                // );
                              } else if (receiptsLength === 0) {
                                if (
                                  approvalStatus === "unsubmitted" ||
                                  approvalStatus === "recalled"
                                ) {
                                  props.handleEditExpense(
                                    getValue(item, "id", "")
                                  );
                                }
                              }
                            }}
                          >
                            <div
                              className="doc_view mx-1"
                              onClick={() =>
                                handleDownloadOnClick(
                                  getValue(doc, `url`, ""),
                                  getValue(doc, `name`, "")
                                )
                              }
                            >
                              {!getValue(doc, `url`, "") ? (
                                <AddSvgComponent color={"gray"} size={26} />
                              ) : getValue(doc, `extension`, "") === ".png" ||
                                getValue(doc, `extension`, "") === ".jpg" ||
                                getValue(doc, `extension`, "") === ".jpeg" ? (
                                <div className="d-flex justify-content-center align-items-center">
                                  <DocumentSvgComponent color={"#408dfb"} />
                                </div>
                              ) : // <img
                              //   // src={getValue(doc, `url`, "")}
                              //   src="/images/image.svg"
                              //   className="img-fluid cursor-pointer static_image "
                              //   width={20}
                              // />
                              getValue(doc, `extension`, "") === ".pdf" ? (
                                <img
                                  src="/images/pdf.svg"
                                  className="img-fluid cursor-pointer static_image"
                                />
                              ) : getValue(doc, `extension`, "") === ".xls" ||
                                getValue(doc, `extension`, "") === ".xlsx" ? (
                                <img
                                  src="/images/excel.svg"
                                  className="img-fluid cursor-pointer static_image"
                                />
                              ) : (
                                <img
                                  src="/images/expense.svg"
                                  className="img-fluid cursor-pointer static_image"
                                />
                              )}
                              {/* {isHovered && (
                              <div
                                className={
                                  getValue(item, `receipts.length`, 0) === 0
                                    ? ""
                                    : "overlay"
                                }
                              >
                                <p>
                                  {getValue(item, `receipts.length`, 0) > 0
                                    ? `+ ${
                                        getValue(item, `receipts.length`, 0) - 1
                                      }`
                                    : ""}
                                </p>
                              </div>
                            )} */}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      const receiptsLength = getValue(
                        item,
                        "receipts.length",
                        0
                      );
                      const approvalStatus = getValue(
                        props,
                        "formInfo.approval_status",
                        ""
                      );
                      if (receiptsLength > 0) {
                        // handleClickGallery(item);
                        // setTimeout(() => {
                        //   openGallery(index);
                        // }, 500);
                      } else if (receiptsLength === 0) {
                        if (
                          approvalStatus === "unsubmitted" ||
                          approvalStatus === "recalled"
                        ) {
                          props.handleEditExpense(getValue(item, "id", ""));
                        }
                      }
                    }}
                  >
                    {/* <div className="border-dotted mb-3"></div> */}
                    <div className="d-flex flex-wrap mb-3 ">
                      <div key={index} className="">
                        <div className="doc_view me-1 d-flex justify-content-center align-items-center">
                          <AddSvgComponent color={"gray"} size={26} />
                          {isHovered && (
                            <div
                              className={
                                getValue(item, `receipts.length`, 0) === 0
                                  ? ""
                                  : "overlay"
                              }
                            >
                              <p>
                                {getValue(item, `receipts.length`, 0) > 0
                                  ? `+ ${
                                      getValue(item, `receipts.length`, 0) - 1
                                    }`
                                  : ""}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* ----------------------- Policy violation  -----------------------  */}
                {getValue(item, `policy_violations.length`, 0) === 1 && (
                  <div className="violationAccordian">
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="d-flex align-items-center justify-content-center">
                        <ViolationSvgComponent size={18} color={"#b15620"} />
                        <p className="desc_text color_violation_desc mt-0">
                          {getValue(
                            item,
                            `policy_violations[${0}].display_msg`,
                            ""
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  {getValue(item, `policy_violations.length`, 0) > 1 && (
                    <div className="violationAccordian">
                      <AccordionItem>
                        <AccordionHeader targetId={`violation`}>
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="d-flex align-items-center justify-content-center">
                              <ViolationSvgComponent
                                size={18}
                                color={"#b15620"}
                              />
                              <p className="desc_text color_violation_desc mt-0">
                                {getValue(
                                  item,
                                  `policy_violations[${0}].display_msg`,
                                  ""
                                )}
                              </p>
                            </div>
                            <div className="violation-arrow-icon-holder">
                              <LineArrowDownSvgComponent
                                color={"#b15620"}
                                size={18}
                              />
                            </div>
                          </div>
                        </AccordionHeader>
                      </AccordionItem>
                    </div>
                  )}
                  <AccordionBody accordionId={"violation"}>
                    <div className="violationAccordian pt-0">
                      {getValue(item, `policy_violations.length`, 0) > 1 && (
                        <div className="d-flex justify-content-center align-items-center">
                          {getValue(item, `policy_violations`, []).map(
                            (item, index) => {
                              if (index > 0)
                                return (
                                  <div className="d-flex justify-content-center align-items-center">
                                    <ViolationSvgComponent
                                      size={18}
                                      color={"#b15620"}
                                    />
                                    <p className="desc_text color_violation_desc mt-0">
                                      {getValue(item, `display_msg`, "")}
                                    </p>
                                  </div>
                                );
                            }
                          )}
                        </div>
                      )}
                    </div>
                  </AccordionBody>
                </div>

                {/* ----------------------- More Info  -----------------------  */}

                <AccordionBody accordionId={"moreInfo"}>
                  {getValue(item, `policy_violations.length`, 0) > 0 && (
                    <div className="mt-3 mb-2">
                      {data.map((item) => {
                        return (
                          <div>
                            <div className="d-flex align-items-center">
                              <p className="small_text__14 ms-2">
                                {getValue(item, `label`, "")}
                              </p>
                              <p className="mx-2">:</p>
                              <p className="small_text__14 ms-2 color_primary">
                                {getValue(item, `value`, "")}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </AccordionBody>

                <div></div>
              </div>
            </UncontrolledAccordion>
          );
        })
      ) : (
        <NotfoundSmall />
      )}
      {/* <ExpenseAmountSection formInfo={getValue(props,`formInfo`,{})}/> */}
      {getValue(list, `length`, 0) > 0 ? (
        <div className="expense_amount_calculation_section">
          <div className="d-flex align-items-center justify-content-end">
            <h6 className="desc_text__16">Total Expense Amount</h6>
            {/* <div className="ms-3">:</div> */}
            <h6 className="header_text expense_amount_currency">
              {getValue(props, `formInfo.currency_symbol`, "")}{" "}
              {formatAmount(getValue(props, `formInfo.total`, ""))}
            </h6>
          </div>
          <div className="d-flex align-items-center justify-content-end bar_section">
            <h6 className="header_tex__14">Amount to be Reimbursed</h6>
            {/* <div className="ms-3">:</div> */}
            <h6 className="header_text expense_amount_currency">
              {getValue(props, `formInfo.currency_symbol`, "")}{" "}
              {formatAmount(getValue(props, `formInfo.total`, ""))}
            </h6>
          </div>
        </div>
      ) : null}
      {getValue(imageList, `length`, 0) > 0 && (
        <LightGalleryPreview onInit={onInit} imageList={imageList} />
      )}
      <Modal
        isOpen={modalReadMore}
        toggle={toggleReadMore}
        backdropClassName={"readMoreBackDrop"}
        modalClassName={"readMoreModalReports"}
        centered
      >
        <ModalHeader toggle={toggleReadMore}>More Details</ModalHeader>
        <ModalBody>
          <div className="more-details-body-wrap">
            {modalInfo.map((item) => {
              return (
                <div>
                  <div className="more-details-label-value">
                    <p className="mb-value-holder">
                      {formatDate(getValue(item, `value`, ""))}
                    </p>
                    <p className="md-label-holder">
                      {getValue(item, `label`, "")}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ReportExpenseListSection;

export function authHeaderEmail(token) {  
  if (token) {
    return {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      "x-tenant-origin":
        window.location.host === "localhost:3000"
          ? "bigappcompany.deep-travel.in"
          : window.location.host,
    };
  } else {
    return {
      "Content-Type": "application/json",
      "x-tenant-origin":
        window.location.host === "localhost:3000"
          ? "bigappcompany.deep-travel.in"
          : window.location.host,
    };
  }
}

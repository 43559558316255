import { formatText } from "./text-helpers";

//------- Trips Constants ---------//
export const travelMode = [
  { value: "flight", label: formatText("flight") },
  { value: "train", label: formatText("train") },
  { value: "bus", label: formatText("bus") },
];

export const travelType = [
  { value: "domestic", label: formatText("domestic") },
  { value: "international", label: formatText("international") },
];

export const airTravelType = [
  { value: "one_way", label: 'One-Way' },
  { value: "round_trip", label: formatText("round_trip") },
  { value: "multi_city", label: 'Multi-City' },
];

export const airTravelClass = [
  { value: "economy", label: formatText("economy") },
  { value: "premium_economy", label: formatText("premium_economy") },
  { value: "business", label: formatText("business") },
  { value: "first", label: formatText("first") },
];

export const timePreferences = [
  { value: "morning", label: '12AM - 8AM' },
  { value: "afternoon", label: '8AM - 12PM' },
  { value: "evening", label: '12PM - 8PM' },
  { value: "night", label: '8PM - 12AM' },
];

export const seatType = [
  { value: "none", label: formatText("none") },
  { value: "window", label: formatText("window") },
  { value: "middle", label: formatText("middle") },
  { value: "aisle", label: formatText("aisle") },
];

export const mealType = [
  { value: "none", label: "None" },
  { value: "avml", label: "Asian Vegetarian Meal" },
  { value: "vgml", label: "Vegetarian Meal(non dailry)" },
  { value: "vlml", label: "Vegetarian Meal(lacto ovo)" },
  { value: "vjml", label: "Vegetarian Jain Meal" },
  { value: "bbml", label: "Baby Meal" },
  { value: "chml", label: "Child Meal" },
  { value: "dbml", label: "Diabetic Meal" },
  { value: "blml", label: "Bland Meal" },
  { value: "fpml", label: "Fruit Platter" },
  { value: "gfml", label: "Glute-Free Meal" },
  { value: "hnml", label: "Hindu (Non-Vegetarian) Meal" },
  { value: "ksml", label: "Kosher Meal" },
  { value: "moml", label: "Muslim Meal" },
  { value: "lfml", label: "Low Cholesterol/Low Fat Meal" },
  { value: "lsml", label: "Low Sodium Meal(No Salt)" },
  { value: "nlml", label: "Non-Lactose Meal" },
  { value: "lcml", label: "Low Calories Meal" },
];

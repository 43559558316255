import React, { useEffect, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import TripsStickyHeader from "./components/StickyHeader";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import {
  getSpecificTrip,
  uploadTripReceipts,
  deleteTripReceipts,
  approveTrip,
  rejectTrip,
  tripComments,
} from "@services/trips.service";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import { toast } from "react-toastify";
import { mediaUpload } from "@services/upload.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import ConfirmModal from "@common/popup/ConfirmModal";
import TripsTabNavigation from "@components/Trip/tab-navigation";
import DeleteModal from "@common/popup/DeleteModal";
import CommonApproverFlowPopup from "@common/popup/approver-flow";
import TripsTitleHeader from "@components/Trip/trips-title-header";
import TripsListSection from "@components/Trip/trips-list-section";
import TripsRightSection from "@components/Trip/trips-right-section";
import { getHistory } from "@services/common.service";
import CommonCommentsHistoryPopup from "@common/popup/comment-history-modal";
import { expenseReportComments } from "@services/expense-reports.service";
import TravelDocumentPopup from "@common/popup/travel-document-modal";
import { getAllTravelDocumentsAdmin } from "@services/settings.service";
import { useGlobalContext } from "@context/global-context";
import NoAccessPage from "@components/common/NoAccess";
import Loader from "@components/common/Loader";

function ClientApprovalTripsDetail(props: any) {
  let navigate = useNavigate();
  let params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  let mainQueryRequest = QueryRequestHelper(urlParams);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = React.useState(true);
  const [formInfo, setFormInfo] = useState({});
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(urlParams).length === 0 && props.permissionAPITriggered) {
      getData(true);
    }
    getAllHistory();
  }, [props.permissionAPITriggered]);
  useEffect(() => {
    if (Object.keys(urlParams).length > 0 && props.permissionAPITriggered) {
      getData(true);
    }
  }, [window.location.href, props.permissionAPITriggered]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [activeTab, setActiveTab] = useState("flight");
  const [hotelList, setHotelList] = useState([]);
  const [flightList, setFlightList] = useState([]);
  const [carList, setCarList] = useState([]);
  const [trainList, setTrainList] = useState([]);
  const [busList, setBusList] = useState([]);

  const getData = async (status: boolean) => {
    try {
      setIsLoading(status);
      let resp = await getSpecificTrip(getValue(params, `id`, ""));
      if (resp) {
        setDocuments(getValue(resp, `data.documents`, []));
        setFormInfo(getValue(resp, `data`, {}));
        const flightList =
          getValue(resp, `data.itineraries.length`, 0) > 0
            ? getValue(resp, `data.itineraries`, []).filter(
                (item: any) => getValue(item, `travel_mode`, "") === "flight"
              )
            : [];
        const hotelList =
          getValue(resp, `data.itineraries.length`, 0) > 0
            ? getValue(resp, `data.itineraries`, []).filter(
                (item: any) => getValue(item, `travel_mode`, "") === "hotel"
              )
            : [];

        const carList =
          getValue(resp, `data.itineraries.length`, 0) > 0
            ? getValue(resp, `data.itineraries`, []).filter(
                (item: any) => getValue(item, `travel_mode`, "") === "car"
              )
            : [];

        const trainList =
          getValue(resp, `data.itineraries.length`, 0) > 0
            ? getValue(resp, `data.itineraries`, []).filter(
                (item: any) => getValue(item, `travel_mode`, "") === "train"
              )
            : [];

        const busList =
          getValue(resp, `data.itineraries.length`, 0) > 0
            ? getValue(resp, `data.itineraries`, []).filter(
                (item: any) => getValue(item, `travel_mode`, "") === "bus"
              )
            : [];

        setFlightList(flightList);
        setHotelList(hotelList);
        setCarList(carList);
        setTrainList(trainList);
        setBusList(busList);
        setIsLoading(false);

        if (getValue(busList, `length`, 0) > 0) {
          setActiveTab(getValue(busList, `length`, 0) > 0 ? "bus" : "flight");
        }
        if (getValue(trainList, `length`, 0) > 0) {
          setActiveTab(
            getValue(trainList, `length`, 0) > 0 ? "train" : "flight"
          );
        }
        if (getValue(carList, `length`, 0) > 0) {
          setActiveTab(getValue(carList, `length`, 0) > 0 ? "car" : "flight");
        }
        if (getValue(hotelList, `length`, 0) > 0) {
          setActiveTab(
            getValue(hotelList, `length`, 0) > 0 ? "hotel" : "flight"
          );
        }
        if (getValue(flightList, `length`, 0) > 0) {
          setActiveTab(
            getValue(flightList, `length`, 0) > 0 ? "flight" : "flight"
          );
        }
        if (getValue(resp, `data.approval_status`, "") === "rejected") {
          getRejectComments();
        }
      } else {
        setIsLoading(false);
      }
      getTravelInformation(getValue(resp, `data.created_by.id`, ""));
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleEdit = () => {
    navigate(`/client/trips/edit/${getValue(params, `id`, "")}`);
  };
  const [showApprovalPopup, setShowApprovalPopup] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                               Upload Section                                */
  /* -------------------------------------------------------------------------- */
  const fileTypes = ["PDF", "JPEG", "XLSX", "CSV", "XLSM", "XLX", "JPG", "PNG"];
  const [documents, setDocuments] = useState<any>([]);
  const [documentList, setDocumentList] = useState<any>([]);
  const [length, setLength] = useState(0);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadStaticLoading, setUploadStaticLoading] = useState(false);
  const handleUploadDocuments = async (e: any) => {
    if (getValue(params, `id`, "")) {
      let lengthOfUploadedFiles = Array.from(e).length;
      setLength(lengthOfUploadedFiles);
    }
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      try {
        setUploadStaticLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          setDocuments((prevDocuments: any) => [
            ...prevDocuments,
            getValue(resp, `data`, {}),
          ]);
          setDocumentList((prevDocuments: any) => [
            ...prevDocuments,
            getValue(resp, `data`, {}),
          ]);
          if (getValue(params, `id`, "")) {
            setUploadLoading(true);
          }
          e = null;
          setUploadStaticLoading(false);
        } else {
          e = null;
          setUploadStaticLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadStaticLoading(false);
      }
    });
  };
  useEffect(() => {
    if (
      length > 0 &&
      getValue(documentList, `length`, 0) > 0 &&
      getValue(documentList, `length`, 0) === length
    ) {
      uploadFiles();
    } else {
      setLength(0);
    }
  }, [length > 0 && getValue(documentList, `length`, 0) === length]);
  const uploadFiles = async () => {
    try {
      let payload = {};
      let requestPayload: any = payload;
      requestPayload["document_ids"] =
        getValue(documents, `length`, 0) > 0
          ? documents.map((item: object) => getValue(item, `id`, ""))
          : [];
      let resp = await uploadTripReceipts(
        getValue(params, `id`, ""),
        removeNullOrUndefinedProperties(requestPayload)
      );
      if (resp) {
        setUploadLoading(false);
        setLength(0);
        setDocumentList([]);
        toast.success(getValue(resp, `message`, ""));
        getData(false);
      } else {
        setLength(0);
        setDocumentList([]);
        setUploadLoading(false);
      }
    } catch (error) {
      setLength(0);
      setDocumentList([]);
      setUploadLoading(false);
    }
  };
  /* ---------------------------- Delete Document -------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteFun = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };
  const handleDeleteFunction = async () => {
    try {
      let payload = {
        document_id: deleteId,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await deleteTripReceipts(
        getValue(params, `id`, ""),
        queryRequest
      );
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getData(false);
      }
    } catch (error) {}
  };

  /* --------------------------  Report Confirm section  ----------------------- */
  const [rejectComments, setRejectComments] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const openConfirmModal = (status: string) => {
    setSubmitStatus(status);
    handleModal();
  };
  const [submitStatus, setSubmitStatus] = useState("");
  const [statusLoading, setStatusLoading] = useState(false);
  const handleSubmitStatus = async (status: string) => {
    if (submitStatus) {
      try {
        let resp;
        if (submitStatus === "approve") {
          setStatusLoading(true);
          resp = await approveTrip(getValue(params, `id`, ""));
        }
        if (submitStatus === "reject") {
          setStatusLoading(true);
          let payload = {
            comments: rejectComments,
          };
          resp = await rejectTrip(getValue(params, `id`, ""), payload);
        }
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          getData(false);
          if (submitStatus === "reject") {
            navigate(`/client/approval/trips?${mainQueryRequest}`);
          }
          setStatusLoading(false);
          handleModal();
        } else {
          setStatusLoading(false);
        }
      } catch (error) {
        setStatusLoading(false);
      }
    } else {
      toast.success("Someting went wrong, Please try after sometime");
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 History section                            */
  /* -------------------------------------------------------------------------- */
  const [historyOpen, setHistoryOpen] = useState(false);
  const toggleHistory = () => {
    setHistoryOpen(!historyOpen);
  };

  const [histoyList, setHistoryList] = useState([]);
  const getAllHistory = async () => {
    try {
      let payload = {
        trip_id: getValue(params, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getHistory(queryRequest);
      if (resp) {
        setHistoryList(getValue(resp, `data.approval_history`, []));
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                                  Reject section                            */
  /* -------------------------------------------------------------------------- */
  const [rejectComment, setRejectComment] = useState([]);
  const getRejectComments = async () => {
    try {
      let payload = {
        comment_type: "reject",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await tripComments(getValue(params, `id`, ""), queryRequest);
      if (resp) {
        setRejectComment(getValue(resp, `data`, {}));
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                         Travel Document section                            */
  /* -------------------------------------------------------------------------- */
  const [openDocument, setOpenDocument] = useState(false);
  const toggleDocument = () => {
    setOpenDocument(!openDocument);
  };
  const [travelDoucments, setTravelDocuments] = useState([]);
  const getTravelInformation = async (id: string) => {
    try {
      let payload = {
        user_id: id,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllTravelDocumentsAdmin(queryRequest);
      if (resp) {
        setTravelDocuments(getValue(resp, `data.travel_documents`, []));
      }
    } catch (error) {}
  };
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "approvals_trips"
      ) ? (
        <NoAccessPage />
      ) : (
        <>
          <TripsStickyHeader
            route={`/client/approval/trips?${mainQueryRequest}`}
            handleEdit={handleEdit}
            handleSubmit={openConfirmModal}
            submitLoading={statusLoading}
            status={submitStatus}
            formInfo={formInfo}
            toggleHistory={toggleHistory}
            histoyList={histoyList}
            rejectComment={rejectComment}
            permissions={getValue(props, `permissions`, [])}
          />
          <div className="trips_main_container trips_details_main_container">
            <div className="trips_main_container_left">
              <TripsTitleHeader formInfo={formInfo} />
              <TripsTabNavigation
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                flightList={flightList}
                hotelList={hotelList}
                carList={carList}
                trainList={trainList}
                busList={busList}
              />
              <TripsListSection
                activeTab={activeTab}
                formInfo={formInfo}
                flightList={flightList}
                hotelList={hotelList}
                carList={carList}
                trainList={trainList}
                busList={busList}
              />
            </div>
            <div className="trips_main_container_right">
              <TripsRightSection
                formInfo={formInfo}
                setShowApprovalPopup={setShowApprovalPopup}
                handleUploadDocuments={handleUploadDocuments}
                uploadLoading={uploadLoading || uploadStaticLoading}
                handleDeleteFun={handleDeleteFun}
                documents={documents}
                //travel document:
                showTravelDocument={
                  // getValue(travelDoucments, `length`, 0) > 0 ? true : false
                  false
                }
                toggleDocument={toggleDocument}
                client
                permissions={getValue(props, `permissions`, [])}
                getData={getData}
              />
            </div>
          </div>
          <CommonApproverFlowPopup
            showUpdatePopup={showApprovalPopup}
            setShowUpdatePopup={setShowApprovalPopup}
            approvers={getValue(formInfo, `trip_approvers`, [])}
          />
          <ConfirmModal
            isOpen={isOpen}
            handleModal={handleModal}
            handleSubmit={handleSubmitStatus}
            submitLoading={statusLoading}
            submitStatus={submitStatus}
            comment={rejectComments}
            setComment={setRejectComments}
          />
          <DeleteModal
            isOpen={isDelete}
            handleModal={handleDelete}
            handleSubmit={handleDeleteFunction}
            deleteValue={deleteValue}
          />
          <CommonCommentsHistoryPopup
            isOpen={historyOpen}
            toggle={toggleHistory}
            list={histoyList}
          />
          <TravelDocumentPopup
            isOpen={openDocument}
            toggle={toggleDocument}
            list={travelDoucments}
          />
        </>
      )}
    </DashboardLayout>
  );
}

export default ClientApprovalTripsDetail;

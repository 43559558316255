import {
  airportsSearchableDropdown,
  baseCurrencySearchableDropdown,
  citiesSearchableDropdown,
  customersSearchableDropdown,
  departmentsHeadSearchableDropdown,
  departmentsSearchableDropdown,
  designationsSearchableDropdown,
  destinationSearchableDropdown,
  expenseCategorySearchableDropdown,
  expenseReportsSearchableDropdown,
  expenseReportsSubmittedSearchableDropdown,
  expenseTypeSearchableDropdown,
  paidThroughSearchableDropdown,
  policieshSearchableDropdown,
  projectsSearchableDropdown,
  rolesSearchableDropdown,
  usersByIDSearchableDropdown,
  usersSearchableDropdown,
} from "./SearchAPIs";

export const getLookupAPIs = (
  value: string,
  id: string,
  search_text: string
) => {
  switch (value) {
    case "currency":
    case "currency_id":
    case "base_currency":
      return baseCurrencySearchableDropdown(search_text);
    case "users":
      return usersSearchableDropdown(id, search_text);
    case "other_travelers":
    case "submits_to":
    case "users_id":
      return usersByIDSearchableDropdown(id, search_text);
    case "customers":
      return customersSearchableDropdown(search_text);
    case "roles":
    case "role":
      return rolesSearchableDropdown(id, search_text);
    case "department":
    case "departments":
      return departmentsSearchableDropdown(id, search_text);
    case "department_head":
      return departmentsHeadSearchableDropdown(search_text);
    case "expense_category":
      return expenseCategorySearchableDropdown(search_text);
    case "expense_type":
      return expenseTypeSearchableDropdown(id, search_text);
    case "expense_reports":
      return expenseReportsSearchableDropdown(search_text);
    case "expense_reports_submitted":
      return expenseReportsSubmittedSearchableDropdown(search_text);
    case "paid_through":
      return paidThroughSearchableDropdown(id, search_text);
    case "policy":
    case "policies":
      return policieshSearchableDropdown(id, search_text);
    case "project":
    case "projects":
      return projectsSearchableDropdown(id, search_text);
    case "airports":
      return airportsSearchableDropdown(search_text);
    case "cities":
      return citiesSearchableDropdown(search_text);
    case "country_code":
    case "destination_country":
      return destinationSearchableDropdown(search_text);
    case "designation":
      return designationsSearchableDropdown(search_text);

    default:
      return null;
  }
};

import { QueryRequestHelper } from "@common/query-request-helper";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import { getAllExpenseEntries } from "@services/expense-entry.service";
import { getValue } from "@utils/lodash";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { mediaUpload } from "@services/upload.service";
import {
  approveExpenseReport,
  attachDocumentsToExpenseReport,
  attachExpenseEntryInReport,
  deleteDocumentsFromExpenseReport,
  expenseReportComments,
  getAllExpenseReportFormEdit,
  getAllExpenseReports,
  getSpecificExpenseReport,
  rejectExpenseReport,
  updateExpenseReport,
} from "@services/expense-reports.service";
import StickyHeader from "./components/StickyHeader";
import DeleteModal from "@common/popup/DeleteModal";
import ReportsPopup from "../add-popup";
import SimpleReactValidator from "simple-react-validator";
import {
  FormAppendDataRequestHelper,
  FormRequestHelper,
} from "@components/common/FormBuilder/request-helper";
import "../expense.scss";
import ConfirmModal from "@common/popup/ConfirmModal";
import CommonApproverFlowPopup from "@common/popup/approver-flow";
import ReportsRightSection from "@components/Reports/reports-right-section";
import ReportsLeftSection from "@components/Reports/reports-left-section";
import Loader from "@components/common/Loader";
import { getHistory } from "@services/common.service";
import CommonCommentsHistoryPopup from "@common/popup/comment-history-modal";
import NoAccessPage from "@components/common/NoAccess";

function ClientApprovalReportDetail(props: any) {
  let navigate = useNavigate();
  let params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  let mainQueryRequest = QueryRequestHelper(urlParams);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = React.useState(true);
  const [formInfo, setFormInfo] = useState({});
  const [list, setList] = useState([]);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(urlParams).length === 0 && props.permissionAPITriggered) {
      getList();
    }
    getAssociatedExpenseList();
    getAllHistory();
    getFormData();
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    if (Object.keys(urlParams).length > 0 && props.permissionAPITriggered) {
      if (urlParams.page_no) {
        setPage_no(parseInt(urlParams.page_no));
      }
      if (urlParams.search_text) {
        setSearchInput(urlParams.search_text);
        setShowSearchTextbox(true);
      }
      if (urlParams.limit) {
        setLimit(parseInt(urlParams.limit));
      }
      getList();
    }
    getData(getValue(params, `id`, ""));
    getAssociatedExpenseList();
  }, [window.location.href, props.permissionAPITriggered]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getList = async () => {
    let payload: any = {
      page_no: urlParams.page_no ? parseInt(urlParams.page_no) : 1,
      page_size: urlParams.limit ? parseInt(urlParams.limit) : limit,
      search_text: urlParams.search_text,
      // status: urlParams.status,
      filter_type: "my_approval",
      sort_by: urlParams.sort_by,
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(true);
      let resp = await getAllExpenseReports(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.expense_reports`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
        getData(getValue(params, `id`, ""));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const [expenseList, setExpenseList] = useState([]);
  const getData = async (id: string) => {
    try {
      setIsLoading(true);
      let resp = await getSpecificExpenseReport(id);
      if (resp) {
        setFormInfo(getValue(resp, `data`, {}));
        setDocuments(getValue(resp, `data.documents`, []));
        setExpenseList(getValue(resp, `data.expense_entries`, []));
        if (getValue(resp, `data.approval_status`, "") === "rejected") {
          getRejectComments();
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search_text, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.search_text) {
      delete params.search_text;
      let payload: any = { ...params };
      let queryRequest = QueryRequestHelper(payload);

      navigate(`${window.location.pathname}?${queryRequest}`);
      // getData();
    }
  };
  const handleChangeSearch = async (e: any) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setSearchInput(e.target.value);

    let payload: any = {
      ...params,
      page_no: 1,
      search_text: e.target.value,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    if (!e.target.value) {
      // getData();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const [selectedIDs, setSelectedIDs] = useState<any>([]);
  const handleCheckUsers = (id: string) => {
    let userIDs =
      getValue(selectedIDs, `length`, 0) > 0
        ? selectedIDs.map((item: string) => item)
        : [];
    if (userIDs.includes(id)) {
      let filterIDs = selectedIDs.filter((item: string) => item !== id);
      setSelectedIDs(filterIDs);
    } else {
      selectedIDs.push(id);
      setSelectedIDs([...selectedIDs]);
    }
  };
  const handleCheck = (id: string) => {};
  const handleCheckAll = (status: string) => {
    if (status === "All") {
      setSelectedIDs([]);
    } else {
      let userIDs =
        getValue(list, `length`, 0) > 0
          ? list.map((item: object) => getValue(item, `id`, ""))
          : [];
      setSelectedIDs(userIDs);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload = {
      ...params,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleChangeLimit = (limit: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      ...params,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  /* -------------------------------------------------------------------------- */
  /*                                 Onchange section                           */
  /* -------------------------------------------------------------------------- */

  const handleNavigate = (id: string) => {
    navigate(`/client/approval/reports/detail/${id}?${mainQueryRequest}`);
  };

  const [activeTab, setActiveTab] = useState(1);
  const handleActiveTab = (activeTab: any) => {
    setActiveTab(activeTab);
  };

  /* -------------------------------------------------------------------------- */
  /*                            Expense Entry section                           */
  /* -------------------------------------------------------------------------- */

  /* --------------------------  Attach Expense Report  ----------------------- */
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [expenseEntryIds, setExpenseEntryIds] = useState<any>([]);
  const [associatedEntries, setAssociatedEntries] = useState([]);

  const handleCheckExpenseEntry = (id: string) => {
    let userIDs =
      getValue(expenseEntryIds, `length`, 0) > 0
        ? expenseEntryIds.map((item: string) => item)
        : [];
    if (userIDs.includes(id)) {
      let filterIDs = expenseEntryIds.filter((item: string) => item !== id);
      setExpenseEntryIds(filterIDs);
    } else {
      expenseEntryIds.push(id);
      setExpenseEntryIds([...expenseEntryIds]);
    }
  };
  const handleCheckAllExpenseEntry = (status: string) => {
    if (status === "All") {
      setExpenseEntryIds([]);
    } else {
      let userIDs =
        getValue(list, `length`, 0) > 0
          ? list.map((item: object) => getValue(item, `id`, ""))
          : [];
      setExpenseEntryIds(userIDs);
    }
  };
  const [loading, setLoading] = useState(false);
  const getAssociatedExpenseList = async () => {
    setLoading(true);
    try {
      let payload: any = {
        page_no: params.page_no ? parseInt(params.page_no) : 1,
        page_size: params.limit ? parseInt(params.limit) : limit,
        search_text: params.search_text,
        // status: params.status,
        sort_by: params.sort_by,
        expense_report_id: getValue(params, `id`, ""),
        // status: "not_associated",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllExpenseEntries(queryRequest);
      if (resp) {
        setAssociatedEntries(getValue(resp, `data.expense_entries`, []));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const [attachLoading, setAttachLoading] = useState(false);
  const attachExpenseReport = async () => {
    setAttachLoading(true);
    let payload = {
      expense_entry_ids: expenseEntryIds,
    };
    try {
      let resp = await attachExpenseEntryInReport(
        getValue(params, `id`, ""),
        payload
      );
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setAttachLoading(false);
        setShowUpdatePopup(!showUpdatePopup);
        setExpenseEntryIds([]);
        getList();
      } else {
        setAttachLoading(false);
      }
    } catch (error) {
      setAttachLoading(false);
    }
  };

  /* --------------------------  Update Expense Report  ----------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [showUpdatePopup1, setShowUpdatePopup1] = useState(false);
  const handleOpenPopup = () => {
    setShowUpdatePopup1(!showUpdatePopup1);
  };
  const [form, setForm] = React.useState<[]>([]);
  const [formInfo1, setFormInfo1] = useState({});

  const getFormData = async () => {
    try {
      setIsLoading(true);
      let resp;
      resp = await getAllExpenseReportFormEdit(getValue(params, `id`, ""));
      if (resp) {
        setFormInfo1(getValue(resp, `data`, {}));
        setForm(getValue(resp, `data.form_layout`, []));
        let length = getValue(resp, `data.form_layout.length`, 0);
        let array = getValue(resp, `data.form_layout`, []);
        for (let i = 0; i < length; i++) {
          FormAppendDataRequestHelper(
            array[i],
            getValue(resp, `data.expense_report`, {})
          );
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoading(true);
        let fields = JSON.parse(JSON.stringify(form));
        let payload = FormRequestHelper(fields);
        let requestPayload: any = payload;
        let resp = await updateExpenseReport(
          getValue(params, `id`, ""),
          removeNullOrUndefinedProperties(requestPayload)
        );
        if (resp) {
          getData(getValue(params, `id`, ""));
          toast.success(getValue(resp, `message`, ""));
          handleOpenPopup();
          setForm([]);
          setFormInfo({});
          getFormData();
          simpleValidator.current.hideMessages();
          forceUpdate(0);
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  const [submitStatus, setSubmitStatus] = useState("");
  const [statusLoading, setStatusLoading] = useState(false);
  const handleSubmitStatus = async (status: string) => {
    if (submitStatus) {
      try {
        let resp;
        if (submitStatus === "approve") {
          setStatusLoading(true);
          resp = await approveExpenseReport(getValue(params, `id`, ""));
        }
        if (submitStatus === "reject") {
          setStatusLoading(true);
          let payload = {
            comments: rejectComments,
          };
          resp = await rejectExpenseReport(getValue(params, `id`, ""), payload);
        }
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          getData(getValue(params, `id`, ""));
          getFormData();
          getList();
          if (submitStatus === "reject") {
            navigate(`/client/approval/reports`);
          }
          setStatusLoading(false);
          handleModal();
        } else {
          setStatusLoading(false);
        }
      } catch (error) {
        setStatusLoading(false);
      }
    } else {
      toast.success("Someting went wrong, Please try after sometime");
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Upload Section                                */
  /* -------------------------------------------------------------------------- */
  const fileTypes = ["PDF", "JPEG", "XLSX", "CSV", "XLSM", "XLX", "JPG", "PNG"];
  const [documents, setDocuments] = useState<any>([]);
  const [documentList, setDocumentList] = useState<any>([]);
  const [length, setLength] = useState(0);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadStaticLoading, setUploadStaticLoading] = useState(false);
  const handleUploadDocuments = async (e: any) => {
    if (getValue(params, `id`, "")) {
      let lengthOfUploadedFiles = Array.from(e).length;
      setLength(lengthOfUploadedFiles);
    }
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      try {
        setUploadStaticLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          setDocuments((prevDocuments: any) => [
            ...prevDocuments,
            getValue(resp, `data`, {}),
          ]);
          setDocumentList((prevDocuments: any) => [
            ...prevDocuments,
            getValue(resp, `data`, {}),
          ]);
          if (getValue(params, `id`, "")) {
            setUploadLoading(true);
          }
          e = null;
          setUploadStaticLoading(false);
        } else {
          e = null;
          setUploadStaticLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadStaticLoading(false);
      }
    });
  };
  useEffect(() => {
    if (
      length > 0 &&
      getValue(documentList, `length`, 0) > 0 &&
      getValue(documentList, `length`, 0) === length
    ) {
      uploadFiles();
    } else {
      setLength(0);
    }
  }, [length > 0 && getValue(documentList, `length`, 0) === length]);
  const uploadFiles = async () => {
    try {
      let payload = {};
      let requestPayload: any = payload;
      requestPayload["document_ids"] =
        getValue(documents, `length`, 0) > 0
          ? documents.map((item: object) => getValue(item, `id`, ""))
          : [];
      let resp = await attachDocumentsToExpenseReport(
        getValue(params, `id`, ""),
        removeNullOrUndefinedProperties(requestPayload)
      );
      if (resp) {
        setUploadLoading(false);
        setLength(0);
        setDocumentList([]);
        toast.success(getValue(resp, `message`, ""));
        getData(getValue(params, `id`, ""));
      } else {
        setLength(0);
        setDocumentList([]);
        setUploadLoading(false);
      }
    } catch (error) {
      setLength(0);
      setDocumentList([]);
      setUploadLoading(false);
    }
  };
  /* ------------------------------  Delete Section ---------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteFun = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };
  const handleDeleteFunction = async () => {
    try {
      let payload = {
        document_id: deleteId,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await deleteDocumentsFromExpenseReport(
        getValue(params, `id`, ""),
        queryRequest
      );
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getList();
      }
    } catch (error) {}
  };
  /* --------------------------  Report Confirm section  ----------------------- */

  const [rejectComments, setRejectComments] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const openConfirmModal = (status: string) => {
    setSubmitStatus(status);
    handleModal();
  };

  /* --------------------------  Report Confirm section  ----------------------- */

  const [showApprovalPopup, setShowApprovalPopup] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                                 History section                            */
  /* -------------------------------------------------------------------------- */
  const [historyOpen, setHistoryOpen] = useState(false);
  const toggleHistory = () => {
    setHistoryOpen(!historyOpen);
  };

  const [histoyList, setHistoryList] = useState([]);
  const getAllHistory = async () => {
    try {
      let payload = {
        report_id: getValue(params, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getHistory(queryRequest);
      if (resp) {
        setHistoryList(getValue(resp, `data.approval_history`, []));
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                                  Reject section                            */
  /* -------------------------------------------------------------------------- */
  const [rejectComment, setRejectComment] = useState([]);
  const getRejectComments = async () => {
    try {
      let payload = {
        comment_type: "reject",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await expenseReportComments(
        getValue(params, `id`, ""),
        queryRequest
      );
      if (resp) {
        setRejectComment(getValue(resp, `data`, {}));
      }
    } catch (error) {}
  };
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "approvals_reports"
      ) ? (
        <NoAccessPage />
      )  : (
        <>
          <StickyHeader
            title={getValue(formInfo, `report_id`, "")}
            route={`/client/approval/reports?${mainQueryRequest}`}
            handleOpenPopup={handleOpenPopup}
            handleSubmit={openConfirmModal}
            submitLoading={statusLoading}
            status={submitStatus}
            formInfo={formInfo}
            toggleHistory={toggleHistory}
            histoyList={histoyList}
            rejectComment={rejectComment}
            permissions={getValue(props, `permissions`, [])}
          />
          {isLoading || !props.permissionAPITriggered ? (
            <Loader />
          ) : (
            <div className="report-detail-wrapper_container">
              <div className="report-detail-wrapper_left">
                <ReportsLeftSection
                  formInfo={formInfo}
                  params={params}
                  setShowUpdatePopup={setShowUpdatePopup}
                  showUpdatePopup={showUpdatePopup}
                  list={associatedEntries}
                  handleCheckUsers={handleCheck}
                  selectedIDs={selectedIDs}
                  handleNavigate={handleNavigate}
                  handleDeleteFun={handleDeleteFun}
                  hideExpenseHeader
                  permissions={getValue(props, `permissions`, [])}
                  // handleEditExpense={handleEditExpense}
                  // openGallery={openGallery}
                />
              </div>
              <div className="report-detail-wrapper_right">
                <ReportsRightSection
                  formInfo={formInfo}
                  setShowApprovalPopup={setShowApprovalPopup}
                  isLoading={isLoading}
                  documents={documents}
                  handleUploadDocuments={handleUploadDocuments}
                  uploadLoading={uploadLoading}
                  handleDeleteFun1={handleDeleteFun}
                  permissions={getValue(props, `permissions`, [])}
                />
              </div>
            </div>
          )}
          <DeleteModal
            isOpen={isDelete}
            handleModal={handleDelete}
            handleSubmit={handleDeleteFunction}
            deleteValue={deleteValue}
          />

          <ReportsPopup
            showUpdatePopup={showUpdatePopup1}
            setShowUpdatePopup={setShowUpdatePopup1}
            form={form}
            setForm={setForm}
            formInfo={formInfo1}
            simpleValidator={simpleValidator}
            handleSubmit={handleSubmit}
            submitLoading={submitLoading}
            editId={getValue(params, `id`, "")}
          />
          <ConfirmModal
            isOpen={isOpen}
            handleModal={handleModal}
            handleSubmit={handleSubmitStatus}
            submitLoading={statusLoading}
            submitStatus={submitStatus}
            comment={rejectComments}
            setComment={setRejectComments}
          />
          <CommonApproverFlowPopup
            showUpdatePopup={showApprovalPopup}
            setShowUpdatePopup={setShowApprovalPopup}
            approvers={getValue(formInfo, `approvers`, [])}
          />
          <CommonCommentsHistoryPopup
            isOpen={historyOpen}
            toggle={toggleHistory}
            list={histoyList}
          />
        </>
      )}
    </DashboardLayout>
  );
}

export default ClientApprovalReportDetail;

import { getValue } from "@utils/lodash";
import "./trip-tab-nav.scss";
import FlightSvgComponent from "@assets/svg-components/trips/flight";
import HotelSvgComponent from "@assets/svg-components/trips/hotel";
import CarSvgComponent from "@assets/svg-components/trips/car";
import TrainSvgComponent from "@assets/svg-components/trips/train";
import BusSvgComponent from "@assets/svg-components/trips/bus";
import TripsDetailSvgComponent from "@assets/svg-components/trips/trip-details";

export default function TripsTabNavigation(props: any) {
  const { travelModes } = props;
  return (
    <div className="common-trips-sticky-header">
      <section className="inner-page-navigation-with-back-cta-buttons position-relative ">
        <div className="container-fluid  d-flex justify-content-center align-items-center ">
          <ul className="inner-page-navigation-with-back-cta-buttons__navigation_left_temp d-inline-flex  cursor-pointer line-seperator">
            <li
              className={`inner-page-navigation-with-back-cta-buttons__nav-list-item_trip  position-relative ${
                getValue(props, `activeTab`, "") === "info" ? "active" : ""
              }`}
              onClick={() => props.setActiveTab("info")}
            >
              <div className="d-flex align-items-center">
                <TripsDetailSvgComponent color={"#D7242E"} size={18} />
                <p className="">Trip Request Details</p>
              </div>
            </li>
            {/* <div className="vr-line"></div> */}
            {getValue(travelModes, `flight_reservation`, false) && (
              <li
                className={`inner-page-navigation-with-back-cta-buttons__nav-list-item_trip  position-relative ml-16 ${
                  getValue(props, `activeTab`, "") === "flight" ? "active" : ""
                }`}
                onClick={() => props.setActiveTab("flight")}
              >
                {/* {getValue(props, `flightList.length`, 0) > 0 && (
                <div className="trip-count-section">
                  {getValue(props, `request.air_travel_type`, "") ===
                  "round_trip"
                    ? "1"
                    : getValue(props, `flightList.length`, 0)}
                </div>
              )} */}
                {getValue(props, `flightList.length`, 0) > 0 && (
                  <div className="trip-count-section">
                    {getValue(props, `flightList.length`, 0)}
                  </div>
                )}
                <div className="d-flex align-items-center">
                  <FlightSvgComponent color={"#D7242E"} size={18} />
                  <p className="">Flight</p>
                </div>
              </li>
            )}
            {getValue(travelModes, `hotel_reservation`, false) && (
              <li
                className={`inner-page-navigation-with-back-cta-buttons__nav-list-item_trip   position-relative ${
                  getValue(props, `activeTab`, "") === "hotel" ? "active" : ""
                }`}
                onClick={() => props.setActiveTab("hotel")}
              >
                {getValue(props, `hotelList.length`, 0) > 0 && (
                  <div className="trip-count-section">
                    {getValue(props, `hotelList.length`, 0)}
                  </div>
                )}
                <div className="d-flex align-items-center">
                  <HotelSvgComponent color={"#D7242E"} size={18} />
                  <p className="">Hotel</p>
                </div>
              </li>
            )}

            {getValue(travelModes, `car_rental`, false) && (
              <li
                className={`inner-page-navigation-with-back-cta-buttons__nav-list-item_trip   position-relative ${
                  getValue(props, `activeTab`, "") === "car" ? "active" : ""
                }`}
                onClick={() => props.setActiveTab("car")}
              >
                {getValue(props, `carList.length`, 0) > 0 && (
                  <div className="trip-count-section">
                    {getValue(props, `carList.length`, 0)}
                  </div>
                )}
                <div className="d-flex align-items-center">
                  <CarSvgComponent color={"#D7242E"} size={18} />
                  <p className="">Car Rental</p>
                </div>
              </li>
            )}
            {getValue(travelModes, `train`, false) && (
              <li
                className={`inner-page-navigation-with-back-cta-buttons__nav-list-item_trip   position-relative ${
                  getValue(props, `activeTab`, "") === "train" ? "active" : ""
                }`}
                onClick={() => props.setActiveTab("train")}
              >
                {getValue(props, `trainList.length`, 0) > 0 && (
                  <div className="trip-count-section">
                    {getValue(props, `trainList.length`, 0)}
                  </div>
                )}
                <div className="d-flex align-items-center">
                  <TrainSvgComponent color={"#D7242E"} size={18} />
                  <p className="">Train</p>
                </div>
              </li>
            )}

            {getValue(travelModes, `bus`, false) && (
              <li
                className={`inner-page-navigation-with-back-cta-buttons__nav-list-item_trip   position-relative ${
                  getValue(props, `activeTab`, "") === "bus" ? "active" : ""
                }`}
                onClick={() => props.setActiveTab("bus")}
              >
                {getValue(props, `busList.length`, 0) > 0 && (
                  <div className="trip-count-section">
                    {getValue(props, `busList.length`, 0)}
                  </div>
                )}
                <div className="d-flex align-items-center">
                  <BusSvgComponent color={"#D7242E"} size={18} />
                  <p className="">Bus</p>
                </div>
              </li>
            )}
          </ul>

          {/* <button
            className={`ascent-button ascent-button--header-buttons ascent-button-secondary`}
          ></button> */}
        </div>
      </section>
    </div>
  );
}

import React from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default () => {
  return (
    <div className="login-wrapper flex-1">
      <img src="/images/logo.png" className="logo-container" />
      <img
        src="/images/login-leftside.webp"
        className="img-fluid static_image_main"
      />
    </div>
  );
};

import { authHeader } from "./auth-header";
import { toast } from "react-toastify";
import { getValue } from "@utils/lodash";
import { config } from "../../env";
import { authHeaderEmail } from "./auth-header-email";

// token
let access_token = localStorage.getItem("accessToken");
export const post = (url, payload) => {
  const requestOptions = {
    method: "POST",
    headers: authHeader(url),
    body: JSON.stringify(payload),
  };

  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const postFileUpload = (url, payload) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + access_token,
      "x-tenant-origin":
        window.location.host === "localhost:3000"
          ? "bigappcompany.deep-travel.in"
          : window.location.host,
    },
    body: payload,
  };

  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const patchFileUpload = (url, payload) => {
  const requestOptions = {
    method: "PATCH",
    headers: { Authorization: "Bearer " + access_token },
    body: payload,
  };

  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const patch = (url, payload) => {
  const requestOptions = {
    method: "PATCH",
    headers: authHeader(url),
    body: JSON.stringify(payload),
  };

  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const put = (url, payload) => {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(url),
    body: JSON.stringify(payload),
  };
  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const putFileUpload = (url, payload) => {
  const requestOptions = {
    method: "PUT",
    body: payload,
  };
  return fetch(`${url}`, requestOptions)
    .then(handleResponseUpload)
    .then((data) => {
      return data;
    });
};

export const Delete = (url) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(url),
  };
  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const DeleteWithPayload = (url, payload) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(url),
    body: JSON.stringify(payload),
  };
  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const get = (url) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: authHeader(url),
    };
    return fetch(`${url}`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  } catch (error) {
    //console.log(error)
  }
};

export const getWithHeader = (url,header) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: authHeaderEmail(header),
    };
    return fetch(`${url}`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  } catch (error) {
    //console.log(error)
  }
};


export const getWithPayload = (url, token) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        "x-tenant-origin":
          window.location.host === "localhost:3000"
            ? "bigappcompany.deep-travel.in"
            : window.location.host,
      },
    };
    return fetch(`${url}`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  } catch (error) {
    //console.log(error)
  }
};

export const getPayload = (url, payload) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(url),
    body: JSON.stringify(payload),
  };
  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

function logout() {
  localStorage.clear();
}

function handleResponse(response) {
  const handleOnClick = async () => {
    // console.log("hoge!");
    // if (window.confirm("Would you like to continue the session?")) {
    // console.log("yes");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
        "x-tenant-origin": "",
      },
      body: JSON.stringify({
        refresh_token: getValue(localStorage, `refreshToken`, ""),
      }),
    };
    return fetch(`${config.API_URL}/auth/user/refresh`, requestOptions).then(
      (data) => {
        data.text().then((text) => {
          // console.log(text);
          // const data = text && JSON.parse(text);
          if (!data.ok) {
            if (data.status === 401) {
              toast.error("Unauthorized");
              logout();
              window.location.href = `/login`;
            }
          } else {
            // console.log(JSON.parse(text));
            let resp = JSON.parse(text);
            localStorage.setItem(
              "accessToken",
              getValue(resp, `data.accessToken`, "")
            );
            localStorage.setItem(
              "refreshToken",
              getValue(resp, `data.refreshToken`, "")
            );
            localStorage.setItem(
              "user",
              JSON.stringify(getValue(resp, `data.user`, ""))
            );
            window.location.reload();
          }
        });
      }
    );
    // } else {
    // 	logout();
    // 	window.location.href = `/login`;
    // }
  };

  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (getValue(data, `statusCode`, 0) === 401) {
      logout();
      window.location.href = `/login`;
    } else {
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          if (getValue(localStorage, `refreshToken`, "")) {
            handleOnClick();
          } else {
            logout();
            window.location.href = `/login`;
          }
        }
        const error = (data && data.message) || response.statusText;
        if (
          typeof data.message === "string" &&
          data.message !== "Unauthorized"
        ) {
          const error = (data && data.message) || response.statusText;
          toast.error(error);
        } else {
          getValue(data, `message`, []).forEach((item) => {
            toast.error(item);
          });
        }
        return Promise.reject(error);
      }
      return data;
    }
  });
}

function handleResponseUpload(response) {
  return getValue(response, `status`, "");
}

import SwapSvgComponent from "@assets/svg-components/trips/swap";
import InputField from "@common/InputRuleFeild";
import { getValue } from "@utils/lodash";
import { formHandleTextArrayChangeByKey } from "@common/handleChange";
import CloseSmallSvgComponent from "@assets/svg-components/close-small";
import TripAirportComponent from "../common/trip_airport";
import TripDateComponent from "../common/trip_date";
import TripFlightTimePreference from "../common/trip_flight_time";
import TripsCreateFlightHeader from "./header";
import TripFlightPreferenceSection from "../common/trip_flight_preference";
import AddSvgComponent from "@assets/svg-components/add";

function TripsCreateFlightList(props: any) {
  const addFlightSection = () => {
    let obj = {
      departure_airport_code: "",
      departure_date: "",
      arrival_airport_code: "",
      arrival_date: "",
      description: "",
      time_preference: "",
      arrival_airport_name: "",
      departure_airport_name: "",
      departure_class: "",
      arrival_class: "",
    };
    props.itineraries.push(obj);
    props.setItenaries([...props.itineraries]);
  };
  const removeFlightSection = (index: number) => {
    let filtered =
      getValue(props, `itineraries.length`, 0) > 0
        ? getValue(props, `itineraries`, []).filter(
            (item: object, i: number) => index !== i
          )
        : [];
    props.setItenaries(filtered);
    props.hideValidation()
  };
  return (
    <div className="trips-flight-tab-main-container">
      {/* <div className="trips_header_bg d-flex align-items-center p-2 px-4 mt-4">
        <h6 className="header_text__18 ">Flight</h6>
      </div> */}
      {getValue(props, `itineraries.length`, 0) > 0 ? (
        <>
          <TripsCreateFlightHeader
            request={props.request}
            setRequest={props.setRequest}
            // validator={props.simpleValidator}
            hideValidation={props.hideValidation}
            travelModes={props.travelModes}
          />
          <div className="">
            {getValue(props, `itineraries`, []).map(
              (item: object, index: number) => {
                if (
                  getValue(props, `request.air_travel_type`, "") ===
                  "multi_city"
                    ? index > -1
                    : index < 1
                )
                  return (
                    <div className="multiple-dyno-wrap">
                      <div className="d-flex align-items-center justify-content-between trip_travel_container position-relative w-100">
                        <div key={index} className="w-100">
                          <div className="trips_from_container d-flex align-items-center flex-wrap_wrap">
                            <div className="d-flex align-items-center position-relative trip_airport_component_wrapper">
                              <TripAirportComponent
                                validator={props.validator}
                                item={item}
                                index={index}
                                setItenaries={props.setItenaries}
                                airport_code={"departure_airport_code"}
                                airport_name={"departure_airport_name"}
                                label={`Where From`}
                              />
                              <TripAirportComponent
                                validator={props.validator}
                                item={item}
                                index={index}
                                setItenaries={props.setItenaries}
                                airport_code={"arrival_airport_code"}
                                airport_name={"arrival_airport_name"}
                                label={"Where To"}
                              />
                              <div className="swap_item">
                                <SwapSvgComponent />
                              </div>
                            </div>
                            <div className="mr-16">
                              <TripDateComponent
                                validator={props.validator}
                                item={item}
                                index={index}
                                date_key={"departure_date"}
                                setItenaries={props.setItenaries}
                                label={"From Date"}
                              />
                            </div>
                            {getValue(props, `request.air_travel_type`, "") ===
                              "one_way" ||
                            getValue(props, `request.air_travel_type`, "") ===
                              "multi_city" ? null : (
                              <div className="">
                                <TripDateComponent
                                  validator={props.validator}
                                  item={item}
                                  index={index}
                                  date_key={"arrival_date"}
                                  setItenaries={props.setItenaries}
                                  minDate={
                                    getValue(item, `departure_date`, "")
                                      ? new Date(
                                          getValue(item, `departure_date`, "")
                                        )
                                      : null
                                  }
                                  label={"To Date"}
                                />
                              </div>
                            )}
                          </div>
                          <div className="d-flex align-items-center d-flex flex-wrap_wrap flight_time_departure_preference">
                            <div className="">
                              <TripFlightTimePreference
                                item={item}
                                index={index}
                                validator={props.validator}
                                label={"Departure Time Preference"}
                                setItenaries={props.setItenaries}
                                preference_key={"departure_time_preference"}
                              />
                            </div>
                            {getValue(props, `request.air_travel_type`, "") ===
                              "round_trip" && (
                              <div className="">
                                <TripFlightTimePreference
                                  item={item}
                                  index={index}
                                  validator={props.validator}
                                  label={"Arrival Time Preference"}
                                  setItenaries={props.setItenaries}
                                  preference_key={"arrival_time_preference"}
                                />
                              </div>
                            )}
                            <div className="">
                              <TripFlightPreferenceSection
                                item={item}
                                index={index}
                                validator={props.validator}
                                label={"Departure Flight Preference"}
                                setItenaries={props.setItenaries}
                                preference_key={"departure_class"}
                              />
                            </div>
                            {getValue(props, `request.air_travel_type`, "") ===
                              "round_trip" && (
                              <div className="">
                                <TripFlightPreferenceSection
                                  item={item}
                                  index={index}
                                  validator={props.validator}
                                  label={"Arrival Flight Preference"}
                                  setItenaries={props.setItenaries}
                                  preference_key={"arrival_class"}
                                />
                              </div>
                            )}
                          </div>
                          <div className="flight_description_wrap">
                            <InputField
                              inputType="TEXTAREA"
                              placeholder="Add Description (Max 200 Characters)"
                              rows={2}
                              name="description"
                              value={getValue(item, `description`, "")}
                              onChange={(e: any) =>
                                formHandleTextArrayChangeByKey(
                                  index,
                                  e,
                                  props.setItenaries
                                )
                              }
                            />
                          </div>
                        </div>
                        {
                          <div>
                            {
                              <div
                                className=" mt-2"
                                onClick={() => removeFlightSection(index)}
                              >
                                <CloseSmallSvgComponent />
                              </div>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  );
              }
            )}
          </div>
        </>
      ) : (
        <>
        <div className="trips_header_bg d-flex align-items-center p-2 px-4 mt-5">
          <h6 className="header_text__18 ">Flight</h6>
        </div>
        <div className="mt-3">
          <p className="text-center mt-3 small_text__14">
            No flights have been included. Kindly select the option below to add
            them.
          </p>
          <div
            className="d-flex justify-content-center align-items-center cursor-pointer  mt-3"
            onClick={addFlightSection}
          >
            <div className="border border-radius__5 d-flex justify-content-center p-2 px-3">
              <AddSvgComponent size={28} color={"#408dfb"} />
              <h6 className="header_text__18 ms-2 color_primary">
                Add Flights
              </h6>
            </div>
          </div>
        </div>
        </>
      )}
      {getValue(props, `request.air_travel_type`, "") === "multi_city" && (
        <div
          className="custom_field_button_container mt-3"
          onClick={addFlightSection}
        >
          <img
            className="custom_field_button_container_image"
            src={`/images/add_circle.svg`}
          />
          <h6 className="header_text__16 color_primary ms-2">
            {getValue(props, `itineraries.length`, 0) === 0
              ? "Add flight"
              : "Add another flight"}
          </h6>
        </div>
      )}
    </div>
  );
}

export default TripsCreateFlightList;

import AdminUserControlRoles from "@pages/Admin/UserControls/Roles";
import AdminUserControlsDepartments from "@pages/Admin/UserControls/Departments";
import AdminUserControlsPolicies from "@pages/Admin/UserControls/Policies";
import AdminUserControlUsers from "@pages/Admin/UserControls/Users";
import AdminUserControlUserDetails from "@pages/Admin/UserControls/Users/User-Details";
import AdminUserControlDepartmentDetails from "@pages/Admin/UserControls/Departments/Department-Details";
import AdminUserControlRolesDetails from "@pages/Admin/UserControls/Roles/Role-Details";
import EmailTemplate from "@pages/Admin/UserControls/EmailTemplate";
import PaidThroughAccount from "@pages/Admin/UserControls/PaidThroughAccount";
import PolicyDetailsPage from "@pages/Admin/UserControls/Policies/Detail";

export const AdminUserControlRoutes = [
  //-------------------- Users Routes -----------------------//
  {
    path: "/admin/user-controls/users",
    name: "admin_user_controls_users",
    component: AdminUserControlUsers,
  },
  {
    path: "/admin/user-controls/users/add",
    name: "admin_user_controls_users",
    component: AdminUserControlUserDetails,
  },
  {
    path: "/admin/user-controls/users/overview/:id",
    name: "admin_user_controls_users",
    component: AdminUserControlUserDetails,
  },
  //-------------------- Roles Routes -----------------------//

  {
    path: "/admin/user-controls/roles",
    name: "admin_user_controls_roles_permission",
    component: AdminUserControlRoles,
  },
  {
    path: "/admin/user-controls/roles/details/:id",
    name: "admin_user_controls_roles_permission",
    component: AdminUserControlRolesDetails,
  },
  {
    path: "/admin/user-controls/roles/add",
    name: "admin_user_controls_roles_permission",
    component: AdminUserControlRolesDetails,
  },

  //-------------------- Department Routes -----------------------//

  {
    path: "/admin/user-controls/departments",
    name: "admin_user_controls_departments",
    component: AdminUserControlsDepartments,
  },
  {
    path: "/admin/user-controls/departments/add",
    name: "admin_user_controls_departments",
    component: AdminUserControlDepartmentDetails,
  },
  {
    path: "/admin/user-controls/departments/detail/:id",
    name: "admin_user_controls_departments",
    component: AdminUserControlDepartmentDetails,
  },

  //-------------------- Policy Routes -----------------------//

  {
    path: "/admin/user-controls/policies",
    name: "admin_user_controls_policies",
    component: AdminUserControlsPolicies,
  },
  {
    path: "/admin/user-controls/policies/add",
    name: "admin_user_controls_policies",
    component: PolicyDetailsPage,
  },
  {
    path: "/admin/user-controls/policies/detail/:id",
    name: "admin_user_controls_policies",
    component: PolicyDetailsPage,
  },

  //-------------------- Email Templates -----------------------//

  {
    path: "/admin/user-controls/email-templates",
    name: "admin_user_controls_email_templates",
    component: EmailTemplate,
  },

  //-------------------- Paid Through Accounts -------------------//
  {
    path: "/admin/modules/paid-through-accounts",
    name: "admin_modules_paid_through_accounts",
    component: PaidThroughAccount,
  },
];
